import { useEffect, useState } from 'react';
import { BreadcrumbItem, BreadcrumbsBar, Wizard } from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../../../store/hooks.tsx';
import i18next from 'i18next';
import { usersActions } from '../../../../../store/users/users.reducers.tsx';
import EstablishmentForm from './user-forms/establishment-form/EstablishmentForm.tsx';
import IdentificationForm from './user-forms/identification-form/IdentificationForm.tsx';
import { Establishment, UserInfo } from '../../../../../models/user.tsx';
import styles from './create-user-page.module.scss';
import CustomizationForm from './user-forms/customization-form/CustomizationForm.tsx';
import { useNavigate } from 'react-router-dom';

import { AllRoles, DentalNotation, Language } from '../../../../../enum/user.ts';
import { getMessageError } from '../../../../../utils/utils.tsx';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../../enum/feedback.ts';
import { useCreateUserMutation } from '../../../../../services/users-rtkq.services.tsx';

const CreateUserPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createUser, { isSuccess: isCreateUserSuccess, error: createUserError }] =
    useCreateUserMutation();
  const [selectedStep, setSelectedStep] = useState(0);
  const steps = [
    { id: 0, title: i18next.t('userForm.identificationGroup', { ns: 'user' }) },
    { id: 1, title: i18next.t('userForm.customizationGroup', { ns: 'user' }) },
    {
      id: 2,
      title: i18next.t('establishmentModal.establishmentGroup', { ns: 'user' })
    }
  ];

  const getEmptyUser = () =>
    new (class implements UserInfo {
      role: AllRoles | undefined;
      clinic: Establishment | undefined;
      designCenter: Establishment | undefined;
      email: string | undefined;
      firstName: string | undefined;
      laboratory: Establishment | undefined;
      language: Language | undefined;
      lastConnection: string | undefined;
      lastName: string | undefined;
      phoneNumber: string | undefined;
      policyValidationDate: string | undefined;
      dentalNotation: DentalNotation | undefined;
      id: number | undefined;
      activationDate: string | undefined;
    })();

  useEffect(() => {
    dispatch(usersActions.setUser(getEmptyUser()));
  }, []);

  const handlePrevious = () => {
    setSelectedStep(selectedStep - 1);
  };

  const handleSubmit = (userParam: UserInfo) => {
    createUser(userParam);
  };

  useEffect(() => {
    if (isCreateUserSuccess) {
      dispatch(usersActions.setUser(getEmptyUser()));
      dispatch(
        feedbackActions.setToast({
          message: i18next.t('userForm.userCreated', { ns: 'user' }),
          type: ToastType.SUCCESS
        })
      );
      navigate('/main-users/users');
    } else if (createUserError) {
      // If we have a dedicated error message, use it
      // If not, let the default error message be displayed
      dispatch(
        feedbackActions.setToast({
          message: getMessageError(createUserError),
          type: ToastType.DANGER
        })
      );
    }
  }, [isCreateUserSuccess, createUserError]);

  const navigateNextStep = () => {
    setSelectedStep(selectedStep + 1);
  };

  return (
    <div className={styles['create-user-form']}>
      <BreadcrumbsBar type={'navigation'}>
        <BreadcrumbItem
          text={i18next.t('tabs.users', { ns: 'user' })}
          link="/main-users/users"></BreadcrumbItem>
        <BreadcrumbItem
          text={i18next.t('userForm.titleCreate', { ns: 'user' })}
          link="users/create"></BreadcrumbItem>
      </BreadcrumbsBar>
      <div className={styles['create-user-form__main']}>
        <Wizard
          options={steps}
          selectedIndex={selectedStep}
          displayedInRow={false}
          className={styles['create-user-form__wizard']}
        />
        {selectedStep === 0 && <IdentificationForm nextCallback={navigateNextStep} />}
        {selectedStep === 1 && (
          <CustomizationForm nextCallback={navigateNextStep} previousCallback={handlePrevious} />
        )}
        {selectedStep === 2 && (
          <EstablishmentForm
            submitCallback={handleSubmit}
            previousCallback={handlePrevious}
            submitcallbackError={createUserError}
          />
        )}
      </div>
    </div>
  );
};

export default CreateUserPage;
