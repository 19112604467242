import {
  ManufacturingOrder,
  ManufacturingOrderFiles,
  QualityControlFileUrl
} from '../models/manufacturing-order';
import { api } from './api';

const prefix_path = '/manufacturing/';

export const manufacturingOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneManufacturingOrder: builder.query<ManufacturingOrder, string>({
      query: (orderNumber: string) => ({
        url: `${prefix_path}orders/${orderNumber}`,
        method: 'GET'
      })
    }),
    deleteManufacturingOrder: builder.mutation<void, { orderNumber: string }>({
      query: ({ orderNumber }) => ({
        url: `/manufacturing/orders/${orderNumber}`,
        method: 'DELETE'
      })
    }),
    patchManufacturingOrder: builder.mutation<ManufacturingOrder, Partial<ManufacturingOrder>>({
      query: ({
        orderNumber,
        ...patch
      }: {
        orderNumber: string;
        patch: Partial<ManufacturingOrder>;
      }) => ({
        url: `/manufacturing/orders/${orderNumber}`,
        method: 'PATCH',
        body: patch
      })
    }),
    addCommentToManufacturingOrder: builder.mutation<
      ManufacturingOrder,
      { orderNumber: string; comment: string }
    >({
      query: ({ orderNumber, comment }: { orderNumber: string; comment: string }) => ({
        url: `${prefix_path}orders/${orderNumber}/comments`,
        method: 'POST',
        body: { comment }
      })
    }),
    getProductionZipFile: builder.query<{ downloadUrl: string }, string>({
      query: (orderNumber: string) => ({
        url: `/orders/${orderNumber}/production-files`,
        method: 'GET'
      })
    }),
    getProductionFiles: builder.query<
      ManufacturingOrderFiles,
      { orderNumber: string; patientReference: string }
    >({
      query: (params) => ({
        url: `/manufacturing/orders/${params.orderNumber}/production-files?patientRef=${params.patientReference}`
      })
    }),
    getQualityControlZipFile: builder.query<QualityControlFileUrl, string>({
      query: (orderNumber) => ({
        url: `${prefix_path}orders/${orderNumber}/quality-control-files`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetOneManufacturingOrderQuery,
  useLazyGetOneManufacturingOrderQuery,
  useDeleteManufacturingOrderMutation,
  usePatchManufacturingOrderMutation,
  useAddCommentToManufacturingOrderMutation,
  useLazyGetProductionZipFileQuery,
  useLazyGetProductionFilesQuery,
  useLazyGetQualityControlZipFileQuery
} = manufacturingOrdersApi;
