import { api } from './api';
import { QueryParams, ServicePaginatedResponse } from '../models/datagrid';
import { HistoryList, Order, OrderForUpdate } from '../models/order';

const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneOrder: builder.query<Order, string>({
      query: (orderNumber: string) => ({
        url: `/orders/${orderNumber}`,
        method: 'GET'
      }),
      providesTags: ['OneOrder']
    }),
    getOrders: builder.query<ServicePaginatedResponse, QueryParams>({
      query: (arg) => {
        const { page, limit, filters, sort } = arg;
        return `/orders?page=${page}&filter.items.deletedAt=$null&limit=${limit}${filters}${sort}`;
      }
    }),
    getHistory: builder.query<HistoryList, string>({
      query: (orderNumber: string) => {
        return `/orders/${orderNumber}/history`;
      }
    }),
    deleteOrder: builder.mutation<void, { orderNumber: string }>({
      query: ({ orderNumber }) => ({
        url: `/orders/${orderNumber}`,
        method: 'DELETE'
      })
    }),
    patchOrder: builder.mutation<Order, OrderForUpdate>({
      query: ({ orderNumber, ...patch }: { orderNumber: string; patch: OrderForUpdate }) => ({
        url: `/orders/${orderNumber}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['OneOrder']
    }),
    addCommentToOrder: builder.mutation<Order, { orderNumber: string; comment: string }>({
      query: ({ orderNumber, comment }: { orderNumber: string; comment: string }) => ({
        url: `/orders/${orderNumber}/comments`,
        method: 'POST',
        body: { comment }
      }),
      invalidatesTags: ['OneOrder']
    })
  })
});

export const {
  useGetOneOrderQuery,
  useLazyGetOneOrderQuery,
  useGetHistoryQuery,
  useLazyGetOrdersQuery,
  useDeleteOrderMutation,
  usePatchOrderMutation,
  useAddCommentToOrderMutation
} = ordersApi;
