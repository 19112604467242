import { useCallback, useState } from 'react';
import { Button, Dialog, SideBarModal } from '@platform-storybook/circlestorybook';
import { FilterReducer, LoadDataProps } from '../../../../models/datagrid.tsx';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.tsx';
import styles from './laboratories-page.module.scss';
import DatagridFeature from '../../../../features/datagrid/DatagridFeature.tsx';
import { getColumnSettings, getFilters, getSort, rowClassName } from './laboratories.tsx';
import { useTranslation } from 'react-i18next';
import { laboratoriesDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors.tsx';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers.tsx';
import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import {
  useDeactivateEstablishmentMutation,
  useLazyGetAllLaboratoriesPaginationQuery
} from '../../../../services/establishments.services.ts';
import { Establishment } from '../../../../models/establishment.tsx';
import CreateEditLaboratoryForm from './CreateEditLaboratoryForm.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { getMessageError } from '../../../../utils/utils.tsx';

const LaboratoriesPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'establishment']);

  const datagridSettings = useAppSelector(laboratoriesDatagridStateSelector);
  const [getLaboratories] = useLazyGetAllLaboratoriesPaginationQuery();
  const [deactivateEstablishment, { isLoading: isLoadingDeactivate }] =
    useDeactivateEstablishmentMutation();

  const [sideBarAddEditLaboOpened, setSideBarAddEditLaboOpened] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [isDialogDeactivateOpened, setIsDialogDeactivateOpened] = useState<boolean>(false);
  const [establishment, setEstablishment] = useState<Establishment>();

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue!);
    const sort = getSort(sortInfo);

    return getLaboratories({ page, limit, filters, sort })
      .then((laboratories) => ({
        data: laboratories.data.data,
        count: laboratories.data.meta.totalItems
      }))
      .catch(() => ({ data: [], count: 0 }));
  };

  const dataSource = useCallback(loadData, [isReloading, isLoadingDeactivate]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'laboratories',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'laboratories',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };

  // kebab actions
  const editCallBack = async (establishment: Establishment) => {
    setEstablishment(establishment);
    setSideBarAddEditLaboOpened(true);
  };

  const deactivateCallback = async (establishment: Establishment) => {
    setEstablishment(establishment);
    setIsDialogDeactivateOpened(true);
  };

  const deactivate = async () => {
    setIsDialogDeactivateOpened(false);
    if (establishment) {
      deactivateEstablishment(establishment.id as number)
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('establishmentDataGrid.actions.deactivate.deactivated', { ns: 'user' }),
              type: ToastType.SUCCESS
            })
          );
          setEstablishment(undefined);
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }
  };

  const handleCloseModal = () => {
    setSideBarAddEditLaboOpened(false);
    setEstablishment(undefined);
  };

  return (
    <div>
      <div className={styles['laboratories-page__actions']}>
        <Button
          category="text"
          label={t('datagrid.removeAllFilters')}
          onClick={() => {
            dispatch(datagridSettingsActions.resetLaboratoriesDatagridFilters());
          }}
        />
        <Button
          data-cy="create-laboratory-btn"
          iconRight="plus"
          label={t('establishmentDataGrid.actions.createLaboratory', { ns: 'user' })}
          onClick={() => {
            setEstablishment(undefined);
            setSideBarAddEditLaboOpened(true);
          }}
        />
      </div>
      <DatagridFeature
        columns={getColumnSettings(editCallBack, deactivateCallback)}
        style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
        filterValue={datagridSettings.filters}
        onFilterValueChange={onFilterValueChange}
        sortInfo={datagridSettings.sort}
        onSortInfoChange={onSortInfoChange}
        dataSource={dataSource}
        rowClassName={rowClassName}
      />

      {sideBarAddEditLaboOpened && (
        <SideBarModal
          title={t(
            `${establishment ? 'establishmentModal.updateLaboratory' : 'establishmentModal.createLaboratory'}`,
            {
              ns: 'user'
            }
          )}
          isOpened={sideBarAddEditLaboOpened}
          closeOnOutsideClick={true}
          onClose={() => handleCloseModal()}>
          <CreateEditLaboratoryForm
            onClose={handleCloseModal}
            establishmentId={establishment?.id}
            setIsReloading={setIsReloading}
          />
        </SideBarModal>
      )}
      <Dialog
        title={t('establishmentDataGrid.actions.deactivate.dialog', {
          ns: 'user',
          user: establishment?.name
        })}
        isOpened={isDialogDeactivateOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('action.deactivate', { ns: 'common' })}
        onCancel={() => setIsDialogDeactivateOpened(false)}
        isLoading={isLoadingDeactivate}
        onConfirm={deactivate}
      />
    </div>
  );
};

export default LaboratoriesPage;
