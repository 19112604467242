import React, { useState } from 'react';
import { IconButton, SideBarModal, Skeleton, Text } from '@platform-storybook/circlestorybook';
import styles from './user-details.module.scss';
import { UserInfo } from '../../models/user.tsx';
import { useTranslation } from 'react-i18next';
import { useUpdateUser } from '../../hooks/useUpdateUser.tsx';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import { isDentistRole } from '../../utils/utils.tsx';
import { DentalNotation, DentistRole, Language } from '../../enum/user.ts';
import CustomizationForm from '../../pages/private/main-users-page/users-page/detail-user-page/user-forms/CustomizationForm.tsx';

interface CustomizationSectionProps {
  user: UserInfo;
  isReadOnly: boolean;
  isLoading: boolean;
}

const CustomizationSection: React.FC<CustomizationSectionProps> = ({
  user,
  isReadOnly,
  isLoading
}) => {
  const { t } = useTranslation(['user']);
  const { handleUpdateUser } = useUpdateUser();

  const [isOpenedCustomization, setIsOpenedCustomization] = useState(false);

  const dentalNotation =
    user?.dentalNotation && Object.values(DentalNotation).includes(user.dentalNotation)
      ? `${t(`userForm.${user.dentalNotation.toLowerCase()}.label`)} (${t(`userForm.${user.dentalNotation.toLowerCase()}.helperText`)})`
      : t('user.unknown');

  return (
    <section className={styles['user-detail__content__section']}>
      <header className={styles['user-detail__content__section__header']}>
        <Text
          label={t('userForm.customizationGroup')}
          size="s"
          className={styles['user-detail__content__section__header__title']}
          type="title"
        />
        {isReadOnly && (
          <IconButton
            iconSize="sm"
            faIconClass="fa-pen-to-square"
            onClick={() => setIsOpenedCustomization(true)}
            radius="full"
          />
        )}
      </header>
      <div className={styles['user-detail__content__section__main__item']}>
        <Text color={ColorPropsEnum.GREY} label={t('userForm.language')} />
        {isLoading ? (
          <Skeleton type="text" />
        ) : (
          <Text
            label={
              user?.language && Object.values(Language).includes(user.language)
                ? t(`language.${user.language}`, { ns: 'common' })
                : t('userForm.unknown')
            }
          />
        )}
      </div>
      <div className={styles['user-detail__content__section__main__item']}>
        <Text color={ColorPropsEnum.GREY} label={t('userForm.dentalNotation')} />
        {isLoading ? <Skeleton type="text" /> : <Text label={dentalNotation} />}
      </div>
      {isDentistRole(user.role as DentistRole) && (
        <div>
          <Text color={ColorPropsEnum.GREY} label={t('userForm.deliveryTime')} />
          {isLoading ? (
            <Skeleton type="text" />
          ) : (
            <Text
              label={
                user?.deliveryTime !== undefined
                  ? t('userForm.day', {
                      count: user?.deliveryTime,
                      daysNumber: user?.deliveryTime
                    })
                  : '-'
              }
            />
          )}
        </div>
      )}
      <SideBarModal
        title={t('userForm.customizationGroup')}
        isOpened={isOpenedCustomization}
        onClose={() => setIsOpenedCustomization(false)}>
        <CustomizationForm
          email={user?.email as string}
          saveCallback={handleUpdateUser}
          closeSideNavCallback={setIsOpenedCustomization}
        />
      </SideBarModal>
    </section>
  );
};

export default CustomizationSection;
