import { Dialog } from '@platform-storybook/circlestorybook';
import i18next from 'i18next';
import { useState } from 'react';
import { useDeactivateLabProductMutation } from '../../../../../services/products-rtkq.services.tsx';
import { useAppDispatch } from '../../../../../store/hooks.tsx';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../../enum/feedback.ts';
import { ProductLabActivation } from '../../../../../models/product.tsx';

type props = {
  productId: number;
  productLab: ProductLabActivation;
  onCancel: () => void;
  onConfirmSuccess: () => void;
  isOpened: boolean;
};

const DeactivateProductForLabDialog = ({
  productId,
  productLab,
  isOpened,
  onCancel,
  onConfirmSuccess
}: props) => {
  const dispatch = useAppDispatch();
  const [deactivateLabProduct] = useDeactivateLabProductMutation();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const confirm = (): void => {
    onConfirmSuccess();
    dispatch(
      feedbackActions.setToast({
        message: i18next.t('products.datagrid.activateLab.dialog.deactivate.deactivated', {
          laboratory: productLab.labName,
          ns: 'catalog'
        }),
        type: ToastType.SUCCESS
      })
    );
  };

  const onConfirmDeactivate = async (): Promise<void> => {
    if (productId && productLab?.labId) {
      setIsLoadingSubmit(true);
      try {
        await deactivateLabProduct({ productId, labId: productLab.labId });
        confirm();
      } finally {
        setIsLoadingSubmit(false);
      }
    }
  };

  const onCancelDeactivate = (): void => {
    if (onCancel) onCancel();
  };

  return (
    <Dialog
      title={i18next.t('products.datagrid.activateLab.dialog.deactivate.text', {
        laboratory: productLab.labName,
        ns: 'catalog'
      })}
      isOpened={isOpened}
      cancelButtonLabel={i18next.t('action.cancel', { ns: 'common' })}
      confirmButtonLabel={i18next.t('action.deactivate', { ns: 'common' })}
      onCancel={() => onCancelDeactivate()}
      onConfirm={() => onConfirmDeactivate()}
      isLoading={isLoadingSubmit}
      data-cy={'deactivate-lab-product-dialog'}
    />
  );
};

export default DeactivateProductForLabDialog;
