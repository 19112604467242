import {
  FilterProps,
  MenuEntry,
  SortDirection,
  SortReducer,
  TypeColWithNamePropertyPlatform
} from '../../../models/datagrid';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import i18next from 'i18next';
import {
  BubblePicto,
  Chips,
  DropdownMenu,
  IconButton,
  Link,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import styles from '../../../features/datagrid/datagrid-feature.module.scss';
import {
  formatDate,
  getLocalizedProperty,
  getPendingStep,
  isCategoryProvisional,
  splitOrderNumber
} from '../../../utils/utils';
import { CommonTypes } from '../../../models/common-types';
import {
  WorkflowModelingPendingStepEnum,
  WorkflowModelingStepEnum,
  WorkflowPendingStepEnum,
  WorkflowStepEnum
} from '../../../enum/workflow-step';
import { faBan, faClockRotateLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Order, OrderItem } from '../../../models/order';
import { ColorPropsEnum, MaterialColorEnum } from '../../../enum/color.enum';
import { ProductForDatagrid, ProductInOrder } from '../../../models/product';
import { getColumnOptions } from '../../../features/datagrid/datagrid.utils.ts';
import { MaterialEnum } from '../../../enum/component-type.ts';
import { GingivaShadeEnum, ToothShadeEnum } from '../../../enum/component.ts';
import { AppDispatch } from '../../../store';
import { feedbackActions } from '../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../enum/feedback.ts';
import moment from 'moment';
import {
  changeStepMenuEntry,
  commentOrderMenuEntry,
  deleteMenuEntry,
  editMenuEntry,
  toggleManufactureMenuEntry
} from '../../../features/datagrid/menu-entry.tsx';

export type ActionBtn<T = Order> = {
  label: string;
  className?: string;
  iconLeft?: string;
  onClick: (data: T) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  'data-cy'?: string;
};

export const getColumnSettings = (
  commonTypes: CommonTypes | undefined,
  dispatch: AppDispatch,
  canConnectedUserAccessOrderDetail: boolean,
  isDashboard: boolean = false
): TypeColWithNamePropertyPlatform[] => [
  {
    ...getColumnOptions('isUrgent', '', 1),
    cellDOMProps: () => ({
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '4px',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }),
    showColumnMenuTool: false,
    textEllipsis: false,
    maxWidth: 50,
    render: ({ value, data }: { value: boolean; data: Order }) => {
      if (data?.isInError) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t('datagrid.status.inError', { ns: 'orders' })}
            </TooltipContent>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className={styles['datagrid-feature__icon--error']}
            />
          </Tooltip>
        );
      }
      if (!data?.toManufacture) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t('datagrid.status.notToManufacture', { ns: 'orders' })}
            </TooltipContent>
            <FontAwesomeIcon icon={faBan} className={styles['datagrid-feature__icon']} />
          </Tooltip>
        );
      }
      if (value && !data.deletedAt) {
        return (
          <Tooltip>
            <TooltipContent>{i18next.t('datagrid.status.urgent', { ns: 'orders' })}</TooltipContent>
            <span className={styles['datagrid-feature__icon-clock--danger']}>
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                className={styles['datagrid-feature__icon']}
              />
            </span>
          </Tooltip>
        );
      }
    }
  },
  {
    ...getColumnOptions(
      'deletedAt',
      i18next.t('datagrid.columns.status', { ns: 'orders' }),
      2,
      false
    ),
    maxWidth: 115,
    showColumnMenuTool: false,
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: [
        { id: 'deleted', label: i18next.t('datagrid.statusDeleted.deleted', { ns: 'orders' }) },
        { id: 'active', label: i18next.t('datagrid.statusDeleted.active', { ns: 'orders' }) }
      ]
    },
    render: ({ value }) => {
      let chip = (
        <Chips
          firstLabel={i18next.t(
            value === null ? 'datagrid.statusDeleted.active' : 'datagrid.statusDeleted.deleted',
            { ns: 'orders' }
          )}
          color={value === null ? ColorPropsEnum.SUCCESS : ColorPropsEnum.DANGER}
        />
      );
      if (value) {
        chip = (
          <Tooltip>
            <TooltipContent>
              {moment(value).format(i18next.t('date.full', { ns: 'common' }))}
            </TooltipContent>
            {chip}
          </Tooltip>
        );
      }
      return chip;
    }
  },
  {
    ...getColumnOptions(
      'orderNumber',
      i18next.t('datagrid.columns.orderNumber', { ns: 'orders' }),
      1
    ),
    type: 'number',
    headerAlign: 'start',
    minWidth: 170,
    maxWidth: 170,
    showColumnMenuTool: false,
    render: ({ value, data }) => {
      const orderNumberSplit = splitOrderNumber(data.orderNumber);
      const orderDetailLink = isDashboard
        ? `/dashboard/order/${data.orderNumber}/detail`
        : `/order/${data.orderNumber}/detail`;
      const orderNumberElement = (
        <>
          {orderNumberSplit.beforeLastPart}
          <span style={{ fontWeight: 'bold' }}>{orderNumberSplit.lastPart}</span>
        </>
      );
      return (
        <div
          className={
            styles[
              data.isInError
                ? 'datagrid-feature__orderNumber--error'
                : 'datagrid-feature__orderNumber'
            ]
          }>
          {canConnectedUserAccessOrderDetail ? (
            <Link data-cy="datagrid-ordernumber-link" href={orderDetailLink}>
              {orderNumberElement}
            </Link>
          ) : (
            orderNumberElement
          )}
          <IconButton
            iconSize={'sm'}
            faIconClass="copy"
            iconStyle="regular"
            onClick={() => {
              navigator.clipboard.writeText(value);
              dispatch(
                feedbackActions.setToast({
                  message: i18next.t('toast.copiedToClipboard', {
                    orderNumber: value,
                    ns: 'orders'
                  }),
                  type: ToastType.SUCCESS
                })
              );
            }}
            radius="full"
          />
        </div>
      );
    }
  },
  {
    ...getColumnOptions(
      'patientReference',
      i18next.t('datagrid.columns.patientReference', { ns: 'orders' }),
      1
    ),
    type: 'number',
    headerAlign: 'start',
    minWidth: 170,
    maxWidth: 170,
    showColumnMenuTool: false,
    render: ({ value, data }) => {
      return (
        <div
          className={
            styles[
              data.isInError
                ? 'datagrid-feature__orderNumber--error'
                : 'datagrid-feature__orderNumber'
            ]
          }>
          {data.patientReference}
          <IconButton
            iconSize={'sm'}
            faIconClass="copy"
            iconStyle="regular"
            onClick={() => {
              navigator.clipboard.writeText(value);
              dispatch(
                feedbackActions.setToast({
                  message: i18next.t('toast.copiedToClipboard', {
                    orderNumber: value,
                    ns: 'orders'
                  }),
                  type: ToastType.SUCCESS
                })
              );
            }}
            radius="full"
          />
        </div>
      );
    }
  },
  {
    ...getColumnOptions('dentistName', i18next.t('datagrid.columns.dentist', { ns: 'orders' }), 2),
    showColumnMenuTool: false,
    minWidth: 60,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ value, data }) => {
      const order = data as Order;
      return <Link href={`/users/${order.dentistEmail}/detail`} label={value}></Link>;
    }
  },
  {
    ...getColumnOptions(
      'submissionDate',
      i18next.t('datagrid.columns.submissionDate', { ns: 'orders' }),
      2
    ),
    maxWidth: 120,
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    render: ({ value }) => {
      if (value) {
        return typeof value === 'string' ? formatDate(new Date(value)) : '-';
      }
    }
  },
  {
    ...getColumnOptions(
      'families',
      i18next.t('datagrid.columns.family', { ns: 'orders' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    textEllipsis: false,
    minWidth: 200,
    cellDOMProps: () => ({
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '4px',
        alignItems: 'center'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes?.families &&
        Object.values(commonTypes?.families).map((family) => {
          return {
            id: family,
            label: i18next.t(`products.families.${family}`, {
              ns: 'catalog'
            })
          };
        })
    },
    render: ({ value }) => {
      if (commonTypes?.families && value && value?.length > 0) {
        return value.map((family: keyof typeof commonTypes.families) => (
          <Chips
            className={styles['datagrid-feature__chips']}
            key={family.toString().toLowerCase()}
            firstLabel={i18next.t(`products.families.${family}`, {
              ns: 'catalog'
            })}
            color={ColorPropsEnum[`FAMILY_${family.toUpperCase()}` as keyof typeof ColorPropsEnum]}
          />
        ));
      }
    }
  },
  {
    ...getColumnOptions(
      getLocalizedProperty('items'),
      i18next.t('datagrid.columns.productType', { ns: 'orders' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    maxWidth: 300,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    render: ({ value, data }) => {
      if (value && value?.length > 0) {
        const products: ProductInOrder[] = value.map((item: OrderItem) => item.product);
        return renderProductsAsPicto(products, data.id);
      }
    }
  },
  {
    ...getColumnOptions(
      'materials',
      i18next.t('datagrid.columns.materials', { ns: 'orders' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    render: ({ data }) => {
      if (data.materials?.length > 0) {
        return data.materials.map((material: MaterialEnum) => {
          const materialLabel = getMaterialLabel(material, data.materialStratification);
          return material ? (
            <>
              <Chips
                key={material}
                className={styles['datagrid-feature__chips']}
                firstLabel={materialLabel}
                color={getMaterialColor(material)}
              />
            </>
          ) : (
            '-'
          );
        });
      }
      return '-';
    }
  },
  {
    ...getColumnOptions('shades', i18next.t('datagrid.columns.shades', { ns: 'orders' }), 2, false),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    render: ({ data }) => {
      if (data.shades?.length > 0) {
        return data.shades.map((shade: ToothShadeEnum | GingivaShadeEnum) => {
          return (
            <Chips
              key={shade}
              className={styles['datagrid-feature__chips']}
              color={ColorPropsEnum[shade as keyof typeof ColorPropsEnum]}
              firstLabel={i18next.t(`shade.${shade.toUpperCase()}`, { ns: 'component' })}
            />
          );
        });
      }
      return '-';
    }
  },
  {
    ...getColumnOptions('currentStep', i18next.t('datagrid.columns.step', { ns: 'orders' }), 2),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        fontWeight: 'bold'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes?.workflowSteps &&
        Object.values(commonTypes?.workflowSteps).map((step) => {
          return {
            id: step,
            label: i18next.t(`order.steps.${step}`, {
              ns: 'orders'
            })
          };
        })
    },
    render: ({ value }) => {
      if (value) {
        return (
          <Tooltip>
            <TooltipContent>{i18next.t(`tooltip.steps.${value}`, { ns: 'orders' })}</TooltipContent>
            <p>
              {i18next.t(`order.steps.${value}`, {
                ns: 'orders'
              }) || '-'}
            </p>
          </Tooltip>
        );
      }
    }
  },
  {
    ...getColumnOptions(
      'labName',
      i18next.t('datagrid.columns.laboratory', { ns: 'orders' }),
      1,
      false
    ),
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    showColumnMenuTool: false,
    defaultFlex: 2
  },
  {
    ...getColumnOptions('tags', i18next.t('datagrid.columns.tags', { ns: 'orders' }), 2, false),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    render: ({ data }) => {
      if (data.tags?.length > 0) {
        return data.tags.map((tag: string) => {
          return (
            <Chips
              key={tag}
              className={styles['datagrid-feature__chips']}
              color={ColorPropsEnum.PURPLE_LIGHT}
              firstLabel={tag}
            />
          );
        });
      }
      return '-';
    }
  },
  {
    ...getColumnOptions(
      'limitShippingDate',
      i18next.t('datagrid.columns.limitShippingDate', { ns: 'orders' }),
      2
    ),
    maxWidth: 115,
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    render: ({ value }) => {
      if (value) {
        return typeof value === 'string'
          ? formatDate(new Date(value), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          : '-';
      }
    }
  },
  {
    ...getColumnOptions(
      'expectedDate',
      i18next.t('datagrid.columns.expectedDate', { ns: 'orders' }),
      2
    ),
    maxWidth: 115,
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    render: ({ value }) => {
      if (value) {
        return typeof value === 'string'
          ? formatDate(new Date(value), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          : '-';
      }
    }
  }
];

export const colActions = (
  deleteCallback: (data: Order) => void,
  toggleManufactureCallback: (data: Order) => Promise<void>,
  changeStepCallback: (data: Order) => void,
  commentOrderCallback: (data: Order) => void
): TypeColWithNamePropertyPlatform[] => [
  {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      const editMenu = [
        editMenuEntry,
        changeStepMenuEntry(data, changeStepCallback),
        toggleManufactureMenuEntry(data, toggleManufactureCallback),
        commentOrderMenuEntry(data, commentOrderCallback)
      ];

      const menuItems: MenuEntry[][] = [];
      menuItems.push(editMenu);
      menuItems.push([deleteMenuEntry(data, deleteCallback)]);
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              faIconClass="ellipsis-vertical"
              isActive={active}
              radius="full"
              data-cy="datagrid-menu"
            />
          )}
          data={menuItems}
        />
      );
    }
  }
];

const getUrlEncodedFilter = (filter: FilterProps): string =>
  `&filter.${filter.name}=$ilike:${encodeURIComponent(filter.value as string)}`;

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value !== null && filter.value !== '')
    ?.forEach((filter) => {
      switch (filter.name) {
        case 'deletedAt':
          filters = `${filters}&filter.deletedAt=${
            filter.value === 'active' ? '$null' : '$not:$null'
          }`;
          break;
        case 'patientReference': {
          filters = `${filters}&filter.patient.reference=$ilike:${encodeURIComponent(filter.value as string)}`;
          break;
        }
        case 'orderNumber':
        case 'dentistName':
          filters = `${filters}${getUrlEncodedFilter(filter)}`;
          break;
        case 'families': {
          // multi select filter
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.items.product.family=$in:${multiSelectValues.join(',')}`;
          break;
        }
        case 'products': {
          filters = `${filters}&filter.${getLocalizedProperty('products')}=$ilike:${filter.value}`;
          break;
        }
        case 'currentStep': {
          // multi select filter
          const multiSelectValuesArr = filter.value as Array<string>;
          let multiSelectValuesString = multiSelectValuesArr.join(',');
          if (multiSelectValuesArr.includes(WorkflowModelingStepEnum.MODELING)) {
            // display all modeling steps when i chose Modeling (to be discussed in user test)
            multiSelectValuesString = multiSelectValuesString.replace(
              WorkflowModelingStepEnum.MODELING,
              [
                ...Object.values(WorkflowModelingStepEnum),
                ...Object.values(WorkflowModelingPendingStepEnum)
              ].join(',')
            );
          }
          // If a selected step has a corresponding pending step, we include it as well
          multiSelectValuesArr.forEach((selectValue) => {
            const pendingStep = getPendingStep(
              selectValue as WorkflowStepEnum
            ) as WorkflowPendingStepEnum;
            if (pendingStep) {
              multiSelectValuesString = multiSelectValuesString.replace(
                selectValue,
                [selectValue, pendingStep].join(',')
              );
            }
          });
          filters = `${filters}&filter.${filter.name}=$in:${multiSelectValuesString}`;
          break;
        }
        case 'submissionDate':
        case 'validationDate':
        case 'expectedDate':
        case 'limitShippingDate': {
          // On Circle Admin, the date filter works in a different way than Circle Lab
          // Because users don't have the same needs here
          // To filter on a specific date & without having to worry about the time of the day
          // Filter between the first hour of that day and the first hour of the next (excluded)
          const startDate = moment(
            filter.value,
            i18next.t('date.small', { ns: 'common' })
          ).toISOString();
          const endDate = moment(filter.value, i18next.t('date.small', { ns: 'common' }))
            .add(1, 'd')
            .toISOString();
          filters = `${filters}&filter.${filter.name}=$btw:${startDate},${endDate}`;
          break;
        }
        case 'tags':
          filters = `${filters}&filter.${filter.name}=$ilike:${(filter.value as string).toUpperCase()}`;
          break;
        default:
          filters = `${filters}${getUrlEncodedFilter(filter)}`;
          break;
      }
    });
  return filters;
};

export const getSort = (sortInfo: SortReducer | SortReducer[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      urlSort += `${urlSort}&sortBy=${sort?.name}:${
        sort?.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
      }`;
    });
  }
  return urlSort;
};

export const rowClassname = ({ data }: { data: Order }): string | undefined => {
  const classNames: string[] = [`order__row--${data.orderNumber}`];

  if ((data.isUrgent && data.toManufacture && !data.deletedAt) || data.isInError) {
    classNames.push(styles['datagrid-feature__row--danger']);
  }
  return classNames.join(' ');
};
const getMaterialLabel = (material: MaterialEnum, materialStratification?: string) => {
  let materialLabel = material ? i18next.t('material.' + material, { ns: 'component' }) : '-';

  if (material && materialStratification) {
    materialLabel += ` ${i18next.t('material.stratification.' + materialStratification, {
      ns: 'component'
    })}`;
  }

  return materialLabel;
};

const getMaterialColor = (material: MaterialEnum) => {
  return Object.keys(MaterialColorEnum).includes(material)
    ? ColorPropsEnum[material.replace('-', '_') as keyof typeof ColorPropsEnum]
    : ColorPropsEnum.WHITE;
};

const renderProductsAsPicto = (products: ProductInOrder[], id: number) => {
  if (products && products?.length > 0) {
    const productsData = computeProductDisplay(products);
    return (
      <>
        {productsData.map((product, index) => (
          <Tooltip key={`prd__${id}__${product.label.toLowerCase().replaceAll(' ', '_')}_${index}`}>
            <TooltipContent data-cy="datagrid__tooltip__products">{product.label}</TooltipContent>
            <BubblePicto
              data-cy="datagrid__products"
              count={product.count}
              url={product.imageUrl}
              isDashedBorder={isCategoryProvisional(product.category)}
              color={
                ColorPropsEnum[
                  `FAMILY_${product.family
                    .toUpperCase()
                    .toUpperCase()}` as keyof typeof ColorPropsEnum
                ]
              }
            />
          </Tooltip>
        ))}
      </>
    );
  }
};

const computeProductDisplay = (products: ProductInOrder[]): ProductForDatagrid[] => {
  const productsData: ProductForDatagrid[] = [];
  const labelPropertyName = getLocalizedProperty('label') as keyof ProductInOrder;
  products.forEach(function (product) {
    const label = product[labelPropertyName] as string;
    const productsDataIndex = productsData.findIndex((data) => data.label === label);
    if (productsDataIndex >= 0) {
      productsData[productsDataIndex].count++;
    } else {
      productsData.push({
        count: 1,
        label: label,
        imageUrl: product.imageUrl,
        family: product.family,
        category: product.category!
      });
    }
  });
  return productsData;
};
