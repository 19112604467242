import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePatchUserMutation } from '../services/users-rtkq.services.tsx';
import { feedbackActions } from '../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../enum/feedback.ts';
import { UserInfo } from '../models/user.tsx';
import { useEffect, useState } from 'react';

export const useUpdateUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['user']);
  const [patchUser, { isLoading: isUpdateUserLoading, isSuccess }] = usePatchUserMutation();

  const [currentSuccessMessage, setCurrentSuccessMessage] = useState<string>('');

  const handleUpdateUser = async (
    userParam: Partial<UserInfo>,
    email: string,
    successMessage?: string
  ) => {
    setCurrentSuccessMessage(successMessage ?? t('userForm.userUpdated'));
    await patchUser({ ...userParam, email });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: currentSuccessMessage,
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isSuccess, dispatch, t]);

  return { handleUpdateUser, isUpdateUserLoading };
};
