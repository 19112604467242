import styles from '../../create-user-page.module.scss';
import { Button, Fieldset, NumberInput, RadioList } from '@platform-storybook/circlestorybook';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks.tsx';
import { userSelector } from '../../../../../../../store/users/users.selectors.tsx';
import { usersActions } from '../../../../../../../store/users/users.reducers.tsx';
import { Language, DentalNotation, DentistRole } from '../../../../../../../enum/user.ts';
import { dentalNotations, isDentistRole, languages } from '../../../../../../../utils/utils.tsx';
import useForm from '../../../../../../../utils/useForm.tsx';
import { StringObject } from '../../../../../../../models/common.tsx';
import { ColorPropsEnum } from '../../../../../../../enum/color.enum.ts';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const CustomizationForm = ({ nextCallback, previousCallback }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const deliveryTimeMandatory = i18next.t('userForm.deliveryTimeMandatory', { ns: 'user' });

  const isDentistOrAssistantRole = isDentistRole(user?.role as DentistRole);

  const saveCustomizationInfo = () => {
    dispatch(
      usersActions.setUser({
        ...user,
        ...values,
        deliveryTime:
          isDentistRole(user?.role as DentistRole) &&
          (values.deliveryTime || values.deliveryTime === 0)
            ? +(values.deliveryTime as number)
            : undefined
      })
    );
  };

  const onSubmit = () => {
    saveCustomizationInfo();
    nextCallback();
  };

  const handlePreviousButton = () => {
    saveCustomizationInfo();
    previousCallback();
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (
        (!values[key] && key !== 'deliveryTime') ||
        (key === 'deliveryTime' && isDentistOrAssistantRole && values[key] === undefined)
      ) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleSelect, handleChange, handleBlur } = useForm(
    {
      dentalNotation: user?.dentalNotation ?? DentalNotation.ISO,
      language: user?.language ?? Language.fr_FR,
      deliveryTime: user?.deliveryTime || user?.deliveryTime === 0 ? +user.deliveryTime : 2
    },
    onSubmit,
    validateAllFieldsRequired
  );

  return (
    <form onSubmit={handleSubmit} className={styles['create-user-form__form']}>
      <Fieldset size="m" className={styles['create-user-form__form__fieldset']}>
        <RadioList
          title={i18next.t('userForm.dentalNotation', { ns: 'user' })}
          options={dentalNotations}
          name="dentalNotation"
          selectedValue={values.dentalNotation}
          onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
          className={styles['create-user-form__form__input']}
        />
        <RadioList
          title={i18next.t('userForm.language', { ns: 'user' })}
          options={languages}
          name="language"
          selectedValue={values.language}
          onClick={(newValue: Language) => handleSelect(newValue, 'language')}
          className={styles['create-user-form__form__input']}
        />
        {isDentistOrAssistantRole && (
          <NumberInput
            label={i18next.t('userForm.deliveryTime', { ns: 'user' })}
            className={styles['create-user-form__form__input']}
            id="deliveryTime"
            name="deliveryTime"
            value={values.deliveryTime}
            unitLabel={i18next.t('userForm.deliveryUnitLabel', { ns: 'user' })}
            min={0}
            helperText={errors.deliveryTime ? deliveryTimeMandatory : undefined}
            variant={errors.deliveryTime ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            radius="small"
            onBlur={handleBlur}
            onChange={handleChange}
            data-cy="user-forms-delivery-delay"
          />
        )}
      </Fieldset>
      <div className="form__buttons">
        <Button
          label={i18next.t('action.previous', { ns: 'common' })}
          category="outlined"
          onClick={handlePreviousButton}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={i18next.t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};
export default CustomizationForm;
