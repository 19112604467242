import { useTranslation } from 'react-i18next';
import { Tabs } from '@platform-storybook/circlestorybook';
import { TabsType } from '../../../models/navigation';
import styles from './main-users-page.module.scss';
import { getUserManagementPermissionsSelector } from '../../../store/auth/permissions.selectors';
import { useAppSelector } from '../../../store/hooks';

const MainUsersPage = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['user']);
  const connectedUserPermissions = useAppSelector(getUserManagementPermissionsSelector);
  const tabs: TabsType[] = [
    {
      label: t('tabs.users'),
      disabled: false,
      to: '/main-users/users'
    },
    {
      label: t('tabs.laboratories'),
      disabled: false,
      to: '/main-users/laboratories'
    },
    {
      label: t('tabs.partners'),
      disabled: !connectedUserPermissions.canViewPartners,
      to: '/main-users/partners'
    }
  ];

  return (
    <section>
      <Tabs tabs={tabs} id={'dashboard'} className={styles['main-users-page__tab']}></Tabs>
      <div>{children}</div>
    </section>
  );
};

export default MainUsersPage;
