import axios, { AxiosResponse } from 'axios';
import { getLocalizedProperty } from '../utils/utils';

export const getProducts = (
  page: number,
  limit: number,
  filters: string,
  sort: string
): Promise<AxiosResponse> => {
  return axios.get(`/products?page=${page}&limit=${limit}${filters}${sort}`);
};

// this has to stay non paginated (limit=-1)
export const getAllActiveProducts = (filters = '', sort?: string): Promise<AxiosResponse> => {
  if (!sort) sort = `&sortBy=${getLocalizedProperty('label')}:ASC`;
  return axios.get(`/products?page=1&limit=-1&filter.deletedAt=$null${filters}${sort}`);
};

export const createProduct = (product: object): Promise<AxiosResponse> => {
  return axios.post(`/products`, product);
};
