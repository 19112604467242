import React, { useState } from 'react';
import styles from './user-details.module.scss'; // Assure-toi d'importer le bon fichier de styles
import {
  Button,
  Chips,
  Dialog,
  IconButton,
  SideBarModal,
  Skeleton,
  Text
} from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../models/user.tsx';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import { formatDate, getMessageError, isDentistRole } from '../../utils/utils.tsx';
import { AdminRole } from '../../enum/user.ts';
import { feedbackActions } from '../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../enum/feedback.ts';
import {
  useDeactivateUserMutation,
  useResetPasswordMutation
} from '../../services/users-rtkq.services.tsx';
import { useAppDispatch } from '../../store/hooks.tsx';
import { useUpdateUser } from '../../hooks/useUpdateUser.tsx';
import IdentificationForm from '../../pages/private/main-users-page/users-page/detail-user-page/user-forms/IdentificationForm.tsx';

interface Props {
  user: UserInfo | null;
  isLoading: boolean;
  isReadOnly: boolean;
}

const UserDetailsSection: React.FC<Props> = ({ user, isLoading, isReadOnly }) => {
  const { t } = useTranslation(['user']);
  const dispatch = useAppDispatch();
  const { handleUpdateUser } = useUpdateUser();

  const [resetPasswordUser] = useResetPasswordMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const [isOpenedIdentification, setIsOpenedIdentification] = useState(false);
  const [isDialogDeactivateOpened, setIsDialogDeactivateOpened] = useState(false);
  const [isDialogResetPasswordOpened, setIsDialogResetPasswordOpened] = useState(false);

  const resetUserPassword = async () => {
    setIsDialogResetPasswordOpened(false);
    if (user) {
      resetPasswordUser(user.email as string)
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('userForm.resetPassword.success'),
              type: ToastType.SUCCESS
            })
          );
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }
  };

  const deactivate = async () => {
    setIsDialogDeactivateOpened(false);
    if (user) {
      deactivateUser(user.email as string)
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('datagrid.deactivate.deactivated'),
              type: ToastType.DANGER
            })
          );
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }
  };

  const tagContent =
    user?.tags && user.tags.length > 0
      ? user.tags.map((tag) => (
          <Chips
            key={tag}
            firstLabel={tag}
            className={styles['user-detail__content__section__main__item__chips']}
          />
        ))
      : '-';

  return (
    <section className={styles['user-detail__content__section']}>
      <header className={styles['user-detail__content__section__header--name']}>
        <div className={styles['user-detail__content__section__header__name-with-status']}>
          {user && (
            <>
              <Text
                className={styles['user-detail__content__section__header__title']}
                label={`${user.firstName} ${user.lastName}`}
                size="s"
                type="title"
              />
              <div className={styles['user-detail__content__section__header__status']}>
                {isLoading ? (
                  <Skeleton type="text" />
                ) : (
                  <Chips
                    firstLabel={t(user.deletedAt ? 'inactive' : 'active', { ns: 'common' })}
                    secondLabel={user.deletedAt ? formatDate(new Date(user.deletedAt)) : ''}
                    color={user.deletedAt ? ColorPropsEnum.DANGER : ColorPropsEnum.SUCCESS}
                  />
                )}
              </div>
              {isReadOnly && (
                <IconButton
                  iconSize="sm"
                  faIconClass="fa-pen-to-square"
                  onClick={() => setIsOpenedIdentification(true)}
                  radius="full"
                />
              )}
            </>
          )}
        </div>
        <div className={styles['user-detail__content__section__actions']}>
          {!user?.deletedAt && (
            <>
              {isReadOnly && (
                <Button
                  className={styles['user-detail__content__section__actions__button']}
                  label={t('action.deactivate', { ns: 'common' })}
                  onClick={() => setIsDialogDeactivateOpened(true)}
                  category="outlined"
                  size="s"
                />
              )}
              <Button
                className={styles['user-detail__content__section__actions__button']}
                label={
                  isReadOnly ? t('userForm.resetPassword.action') : t('userForm.resetMyPassword')
                }
                onClick={() => setIsDialogResetPasswordOpened(true)}
                size="s"
              />
            </>
          )}

          {/* Dialog de désactivation */}
          <Dialog
            title={t('datagrid.deactivate.dialog.title', {
              user: `${user?.firstName} ${user?.lastName}`
            })}
            text={t('datagrid.deactivate.dialog.text')}
            isOpened={isDialogDeactivateOpened}
            cancelButtonLabel={t('action.cancel', { ns: 'common' })}
            confirmButtonLabel={t('action.deactivate', { ns: 'common' })}
            onCancel={() => setIsDialogDeactivateOpened(false)}
            onConfirm={deactivate}
          />

          {/* Dialog de réinitialisation de mot de passe */}
          <Dialog
            title={t('userForm.resetPassword.dialog.title', {
              user: `${user?.firstName} ${user?.lastName}`
            })}
            text={t('userForm.resetPassword.dialog.text', { email: user?.email })}
            isOpened={isDialogResetPasswordOpened}
            cancelButtonLabel={t('action.cancel', { ns: 'common' })}
            confirmButtonLabel={t('userForm.resetPassword.dialog.action')}
            onCancel={() => setIsDialogResetPasswordOpened(false)}
            onConfirm={resetUserPassword}
          />
        </div>
      </header>

      {/* Corps de la section */}
      <div className={styles['user-detail__content__section__main']}>
        <div>
          <div className={styles['user-detail__content__section__main__item']}>
            <Text color={ColorPropsEnum.GREY} label={t('userForm.email')} />
            {isLoading ? <Skeleton type="text" /> : <Text label={user?.email || ''} />}
          </div>

          {!isLoading && user?.phoneNumber && (
            <div className={styles['user-detail__content__section__main__item']}>
              <Text color={ColorPropsEnum.GREY} label={t('userForm.phone')} />
              <Text label={user?.phoneNumber} />
            </div>
          )}

          <div className={styles['user-detail__content__section__main__item']}>
            <Text color={ColorPropsEnum.GREY} label={t('userForm.role')} />
            {isLoading ? (
              <Skeleton type="text" />
            ) : (
              <Text
                label={
                  user?.role === AdminRole.ADMIN_PARTNER && user?.partnerCode
                    ? `${t(`roles.${user.role}`)} - ${user.partnerCode}`
                    : t(`roles.${user?.role}`)
                }
              />
            )}
          </div>

          {user?.role && isDentistRole(user?.role) && (
            <div className={styles['user-detail__content__section__main__item']}>
              <Text color={ColorPropsEnum.GREY} label={t('userForm.tags')} />
              {isLoading ? <Skeleton type="text" /> : tagContent}
            </div>
          )}
        </div>
      </div>

      {/* SideBarModal pour l'identification */}
      <SideBarModal
        title={t('userForm.identificationGroup')}
        isOpened={isOpenedIdentification}
        onClose={() => setIsOpenedIdentification(false)}>
        <IdentificationForm
          email={user?.email as string}
          saveCallback={handleUpdateUser}
          closeSideNavCallback={setIsOpenedIdentification}
        />
      </SideBarModal>
    </section>
  );
};

export default UserDetailsSection;
