import { useTranslation } from 'react-i18next';
import styles from '../create-component-page.module.scss';

import { Fieldset, Button, CheckboxList, Text } from '@platform-storybook/circlestorybook';

import useForm from '../../../../../../utils/useForm';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  allowedShapesCheckboxSelector,
  allowedStructuresCheckboxSelector,
  allowedImplantAttachmentsCheckboxSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { componentSelector } from '../../../../../../store/components/components.selectors';
import { componentsActions } from '../../../../../../store/components/components.reducers';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const ShapeStructureForm = ({ nextCallback, previousCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const allowedShapesCheckbox = useAppSelector(allowedShapesCheckboxSelector);
  const allowedStructuresCheckbox = useAppSelector(allowedStructuresCheckboxSelector);
  const allowedImplantAttachmentsCheckbox = useAppSelector(
    allowedImplantAttachmentsCheckboxSelector
  );
  const component = useAppSelector(componentSelector);

  const saveComponentInfo = () => {
    dispatch(componentsActions.setComponent({ ...component, ...values }));
  };

  const onSubmit = () => {
    nextCallback();
    saveComponentInfo();
  };
  const { values, handleCheck, handleSubmit } = useForm(
    {
      shapes: component?.shapes ?? allowedShapesCheckbox,
      structures: component?.structures ?? allowedStructuresCheckbox,
      implantAttachments: component?.implantAttachments ?? allowedImplantAttachmentsCheckbox
    },
    onSubmit
  );

  const handlePreviousButton = () => {
    saveComponentInfo();
    previousCallback();
  };

  const noChoiceAvailable =
    allowedShapesCheckbox.length === 0 &&
    allowedStructuresCheckbox?.length === 0 &&
    allowedImplantAttachmentsCheckbox?.length === 0;

  return (
    <form onSubmit={handleSubmit} className={styles['create-component-page__form']}>
      <Fieldset size="m" className={styles['create-component-page__form__fieldset']}>
        {noChoiceAvailable ? (
          <Text label={t('components.componentForm.noChoice')}></Text>
        ) : (
          <>
            {allowedShapesCheckbox?.length > 0 && (
              <CheckboxList
                data-cy="component-shapes"
                title={t('components.componentForm.shapeInput.label')}
                name="shapes"
                data={values?.shapes}
                onClick={handleCheck}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
            {allowedStructuresCheckbox?.length > 0 && (
              <CheckboxList
                data-cy="component-structures"
                name="structures"
                title={t('components.componentForm.structureInput.label')}
                data={values?.structures}
                onClick={handleCheck}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
            {allowedImplantAttachmentsCheckbox.length > 0 && (
              <CheckboxList
                data-cy="component-attachments"
                name="implantAttachments"
                title={t('components.componentForm.implantAttachmentsInput.label')}
                data={values?.implantAttachments}
                onClick={handleCheck}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
          </>
        )}
      </Fieldset>
      <div className="form__buttons">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="outlined"
          onClick={handlePreviousButton}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default ShapeStructureForm;
