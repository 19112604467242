import { useCallback, useEffect, useState } from 'react';
import styles from './orders-page.module.scss';
import { Button, SideBarModal, Tabs } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TabsType } from '../../../models/navigation';
import { useTranslation } from 'react-i18next';
import { useLazyGetOrdersQuery, usePatchOrderMutation } from '../../../services/orders.services';
import DatagridFeature from '../../../features/datagrid/DatagridFeature';
import { FilterReducer, LoadDataProps, ServicePaginatedResponse } from '../../../models/datagrid';
import { colActions, getColumnSettings, getFilters, getSort, rowClassname } from './orders';
import { datagridSettingsActions } from '../../../store/datagrids-settings/datagrids-settings.reducers';
import { ordersDatagridStateSelector } from '../../../store/datagrids-settings/datagrids-settings.selectors';
import DeleteOrderDialog from './delete-order-dialog/DeleteOrderDialog.tsx';

import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import ExportManufacturedOrdersForm from './export-orders-form/ExportManufacturedOrdersForm';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { useGetCommonTypesQuery } from '../../../services/common-types-rtkq.services';
import DatagridReload from '../../../features/datagrid/DatagridReload.tsx';
import { Order } from '../../../models/order.tsx';
import { usePatchManufacturingOrderMutation } from '../../../services/manufacturing-orders-rtkq.services.tsx';
import { WorkflowStepEnum, WorkFlowStepPreModelingEnum } from '../../../enum/workflow-step.ts';
import { feedbackActions } from '../../../store/feedback/feedback.reducer.tsx';
import { getMessageError } from '../../../utils/utils.tsx';
import { ToastType } from '../../../enum/feedback.ts';
import { getSmallOrderNumber } from '../../../utils/order.utils.ts';
import ChangeStepForm from './change-step-form/ChangeStepForm.tsx';
import Comments from '../../../features/comments/Comments.tsx';
import {
  getOrderManagementPermissionsSelector,
  getPrivateAppPermissionsSelector
} from '../../../store/auth/permissions.selectors.tsx';

enum SidebarContentToDisplay {
  CHANGE_STEP_FORM = 0,
  ADD_COMMENT_FORM = 1,
  EXPORT = 2
}

const OrdersPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'orders']);
  const datagridSettings = useAppSelector(ordersDatagridStateSelector);
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const connectedUserPermissions = {
    ...useAppSelector(getOrderManagementPermissionsSelector),
    ...useAppSelector(getPrivateAppPermissionsSelector)
  };
  const [getOrders] = useLazyGetOrdersQuery();

  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [sidebarContentToDisplay, setSidebarContentToDisplay] = useState<
    SidebarContentToDisplay | undefined
  >();
  const [reloadDate, setReloadDate] = useState<Date>();
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState<boolean>(false);
  const [toggleManufactureOrder] = usePatchOrderMutation();
  const [toggleManufactureManufacturingOrder] = usePatchManufacturingOrderMutation();

  const handleMarkToManufacture = async (order: Order) => {
    try {
      await toggleManufactureOrder({
        orderNumber: order?.orderNumber,
        toManufacture: !order.toManufacture
      }).unwrap();

      if (
        !Object.values(WorkFlowStepPreModelingEnum).includes(
          order?.currentStep as WorkFlowStepPreModelingEnum
        )
      ) {
        await toggleManufactureManufacturingOrder({
          orderNumber: order?.orderNumber,
          toManufacture: !order.toManufacture
        }).unwrap();
      }
      setIsReloadNeeded(true);
      dispatch(
        feedbackActions.setToast({
          message: t(
            order?.toManufacture ? 'toast.setNotToBeManufactured' : 'toast.setToBeManufactured',
            { ns: 'orders' }
          ),
          type: ToastType.SUCCESS
        })
      );
    } catch (error) {
      dispatch(
        feedbackActions.setToast({
          message: getMessageError(error),
          type: ToastType.DANGER
        })
      );
    }
  };

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);

    return getOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders: ServicePaginatedResponse) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, [isReloadNeeded]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'orders',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    let newSort = newSortInfo as TypeSingleSortInfo;
    // For now, we reset filters at every changes
    if (Array.isArray(newSort)) {
      newSort = newSort[newSort.length - 1];
    }
    dispatch(datagridSettingsActions.setDatagridSort({ datagrid: 'orders', sort: newSort }));
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetOrdersDatagridFilters());
  };

  const tabs: TabsType[] = [
    {
      label: t('tabs.orders', { ns: 'orders' }),
      disabled: false,
      to: '/orders'
    }
  ];

  const onSubmitChangeStepForm = () => {
    setIsReloadNeeded(true);
    setSideBarOpened(false);
    setSidebarContentToDisplay(undefined);
  };

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
    setSidebarContentToDisplay(undefined);
  };

  const handleDelete = (order: Order) => {
    setSelectedOrder(order);
    setIsDeleteDialogOpened(true);
  };

  const handleChangeStep = (order: Order) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setSidebarContentToDisplay(SidebarContentToDisplay.CHANGE_STEP_FORM);
  };

  const handleCommentOrder = (order: Order) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setSidebarContentToDisplay(SidebarContentToDisplay.ADD_COMMENT_FORM);
  };

  return (
    <>
      <div className={styles['orders-page__tab']}>
        <Tabs tabs={tabs} id={'dashboard'}></Tabs>
      </div>
      <div className={styles['orders-page__actions']} id={'actions-buttons'}>
        <Button
          data-dy="remove-filter-button"
          category="text"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        {connectedUserPermissions.canExportOrders && (
          <Button
            data-cy="export-orders-button"
            className={styles['orders-page__actions__export']}
            category="outlined"
            iconLeft="fa-file-excel"
            id={'export-manufactured-orders'}
            label={t('datagrid.export.title', { ns: 'orders' })}
            onClick={() => setSidebarContentToDisplay(SidebarContentToDisplay.EXPORT)}
          />
        )}
        {reloadDate && (
          <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
        )}
      </div>
      <div className={styles['orders-page__content']}>
        {!areCommonTypesLoading && (
          <DatagridFeature
            key="orders"
            style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
            columns={[
              ...getColumnSettings(
                commonTypes,
                dispatch,
                connectedUserPermissions.canAccessOrderDetail
              ),
              ...colActions(
                handleDelete,
                handleMarkToManufacture,
                handleChangeStep,
                handleCommentOrder
              )
            ].filter((column) => column !== undefined)}
            filterValue={datagridSettings.filters}
            onFilterValueChange={onFilterValueChange}
            sortInfo={datagridSettings.sort}
            onSortInfoChange={onSortInfoChange}
            dataSource={dataSource}
            rowClassName={rowClassname}
            rowHeight={46}
          />
        )}
        {SidebarContentToDisplay.CHANGE_STEP_FORM === sidebarContentToDisplay && selectedOrder && (
          <SideBarModal
            title={t('changeStepModal.title', {
              orderNumber: getSmallOrderNumber(selectedOrder.orderNumber),
              ns: 'orders'
            })}
            isOpened={sideBarOpened}
            closeOnOutsideClick={true}
            onClose={handleClickCloseSidebar}>
            <ChangeStepForm
              onSubmitChangeStepOrderCallback={onSubmitChangeStepForm}
              orderNumber={selectedOrder.orderNumber}
              currentStep={selectedOrder.currentStep as WorkflowStepEnum}
            />
          </SideBarModal>
        )}

        {SidebarContentToDisplay.ADD_COMMENT_FORM === sidebarContentToDisplay && selectedOrder && (
          <SideBarModal
            title={t('comments.title', {
              orderNumber: selectedOrder.orderNumber,
              ns: 'comment'
            })}
            isOpened={sideBarOpened}
            closeOnOutsideClick={true}
            onClose={handleClickCloseSidebar}>
            <Comments
              orderNumber={selectedOrder.orderNumber}
              inSidebar={true}
              onFirstCommentAddedCallback={() => setIsReloadNeeded(true)}
            />
          </SideBarModal>
        )}
      </div>
      {SidebarContentToDisplay.EXPORT === sidebarContentToDisplay && (
        <SideBarModal
          title={t('datagrid.export.title', {
            ns: 'orders'
          })}
          isOpened={true}
          closeOnOutsideClick={true}
          onClose={() => setSidebarContentToDisplay(undefined)}>
          <ExportManufacturedOrdersForm
            onSubmitCallback={(keepOpened: boolean) =>
              setSidebarContentToDisplay(keepOpened ? SidebarContentToDisplay.EXPORT : undefined)
            }></ExportManufacturedOrdersForm>
        </SideBarModal>
      )}

      {isDeleteDialogOpened && (
        <DeleteOrderDialog
          title={t(`dialog.deleteOrder.text`, {
            ns: 'orders',
            orderNumber: getSmallOrderNumber(selectedOrder?.orderNumber as string)
          })}
          orderNumber={selectedOrder?.orderNumber}
          cancelButtonLabel={t('action.cancel', { ns: 'common' })}
          confirmButtonLabel={t(`action.delete`, {
            ns: 'common'
          })}
          isOpened={isDeleteDialogOpened}
          onCancel={() => setIsDeleteDialogOpened(false)}
          onConfirmSuccess={() => {
            setIsDeleteDialogOpened(false);
            setIsReloadNeeded(true);
          }}
        />
      )}
    </>
  );
};

export default OrdersPage;
