export enum HistoryKnownActionEnum {
  DELETED = 'DELETED',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  UPDATED_PRODUCT = 'UPDATED_PRODUCT',
  UPDATED_COMPONENT = 'UPDATED_COMPONENT',
  MANUFACTURED = 'MANUFACTURED',
  ADDED_FILE = 'ADDED_FILE',
  ADDED_ITEM = 'ADDED_ITEM',
  ADDED_COMMENT = 'ADDED_COMMENT',
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  CONTROLLED = 'CONTROLLED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  DESIGNED_CIRCLE_CAD = 'DESIGNED_CIRCLE_CAD',
  ANALYZED_CIRCLE_CAD = 'ANALYZED_CIRCLE_CAD',
  DESIGNED_CIRCLE_ONE = 'DESIGNED_CIRCLE_ONE',
  SENT_TO_MANUFACTURING = 'SENT_TO_MANUFACTURING'
}
