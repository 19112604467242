import styles from './create-edit-laboratory.module.scss';
import { Button, Dropdown, TextField, Box, Skeleton } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Country } from '../../../../enum/user.ts';
import useForm from '../../../../utils/useForm.tsx';
import { Establishment } from '../../../../models/establishment.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { EstablishmentType } from '../../../../enum/establishment.ts';
import {
  useCreateEstablishmentMutation,
  useGetOneEstablishmentQuery,
  useUpdateEstablishmentMutation
} from '../../../../services/establishments.services.ts';
import { StringObject } from '../../../../models/common.tsx';
import { ColorPropsEnum } from '../../../../enum/color.enum.ts';
import { toKebabCase } from '../../../../utils/utils.tsx';

type props = {
  establishmentId?: number;
  setIsReloading?: (loading: boolean) => void;
  onClose: () => void;
};

const CreateEditLaboratoryForm = ({ establishmentId, onClose, setIsReloading }: props) => {
  const { t } = useTranslation(['user']);
  const dispatch = useDispatch();

  const [createEstablishment, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate }] =
    useCreateEstablishmentMutation();
  const [updateEstablishment, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] =
    useUpdateEstablishmentMutation();

  const oneEstablishment = useGetOneEstablishmentQuery(establishmentId as number, {
    skip: !establishmentId
  });

  const renderTextField = (field: keyof typeof values) =>
    establishmentId && oneEstablishment.isLoading ? (
      <Skeleton key={field} type="text" className="skeleton" width="100%" height="50px" />
    ) : (
      <TextField
        key={field}
        label={t(`establishmentModal.${field}`)}
        id={field}
        name={field}
        value={values[field]}
        type="text"
        helperText={
          field === 'circleCloudRegion'
            ? t('establishmentModal.circleCloudRegionHelperText')
            : errors[field]
              ? t(`establishmentModal.${field}Mandatory`)
              : ''
        }
        variant={errors[field] ? 'danger' : 'default'}
        className={styles['create-edit-laboratory__form__textfield']}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={t(`establishmentModal.placeholder.${field}`)}
        data-cy={`create-edit-laboratory-${toKebabCase(field as string)}`}
      />
    );

  const submit = () => {
    const newEstablishment: Establishment = {
      type: EstablishmentType.LABORATORY,
      name: values.name as string,
      circleCloudRegion:
        values.circleCloudRegion !== '' ? (values.circleCloudRegion as string) : 'eu-west-1',
      address: {
        address: values.address as string,
        additionalAddress: values.additionalAddress as string,
        zipCode: values.zipCode as string,
        region: values.region as string,
        city: values.city as string,
        country: values.country as Country
      }
    };

    if (establishmentId) {
      const establishmentToUpdate: Establishment = {
        id: establishmentId,
        ...newEstablishment
      };
      updateEstablishment({ ...establishmentToUpdate });
    } else {
      createEstablishment(newEstablishment);
    }
  };

  // change isReload in parent when isLoadingCreate or isLoadingUpdate changes.
  useEffect(() => {
    if (setIsReloading) {
      setIsReloading(isLoadingCreate || isLoadingUpdate);
    }
  }, [isLoadingCreate, isLoadingUpdate]);

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate) {
      dispatch(
        feedbackActions.setToast({
          message: establishmentId
            ? t('establishmentDataGrid.toast.updated')
            : t('establishmentDataGrid.toast.created'),
          type: ToastType.SUCCESS
        })
      );
      onClose();
    }
  }, [isSuccessCreate, isSuccessUpdate]);

  // useEffect to update the inputs when we call getOneEstablishment in edit mode.
  useEffect(() => {
    if (oneEstablishment.isSuccess) {
      setValues({
        name: oneEstablishment.data.name ?? '',
        circleCloudRegion: oneEstablishment.data.circleCloudRegion ?? '',
        address: oneEstablishment.data.address?.address ?? '',
        additionalAddress: oneEstablishment.data.address?.additionalAddress ?? '',
        zipCode: oneEstablishment.data.address?.zipCode ?? '',
        city: oneEstablishment.data.address?.city ?? '',
        region: oneEstablishment.data.address?.region ?? '',
        country: oneEstablishment.data.address?.country ?? Country.FR
      });
    }
  }, [oneEstablishment.isSuccess]);

  const countries = [
    Object.values(Country).map((country) => {
      return {
        label: t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
        value: country
      };
    })
  ];

  const initialValue = {
    name: oneEstablishment?.data?.name ?? '',
    circleCloudRegion: oneEstablishment?.data?.circleCloudRegion ?? '',
    address: oneEstablishment?.data?.address?.address ?? '',
    additionalAddress: oneEstablishment?.data?.address?.additionalAddress ?? '',
    zipCode: oneEstablishment?.data?.address?.zipCode ?? '',
    city: oneEstablishment?.data?.address?.city ?? '',
    region: oneEstablishment?.data?.address?.region ?? '',
    country: oneEstablishment?.data?.address?.country ?? undefined
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (!values[key] && !['additionalAddress', 'region', 'circleCloudRegion'].includes(key)) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, setValues, errors, handleChange, handleBlur, handleSelect, handleSubmit } =
    useForm(initialValue, submit, validateAllFieldsRequired);

  return (
    <Box color={ColorPropsEnum.WHITE} className={styles['create-edit-laboratory']}>
      <form className={styles['create-edit-laboratory__form']} onSubmit={handleSubmit}>
        {[
          'name',
          'circleCloudRegion',
          'address',
          'additionalAddress',
          'zipCode',
          'city',
          'region'
        ].map(renderTextField)}
        <Dropdown
          label={t('establishmentModal.country')}
          data={countries}
          value={values?.country}
          helperText={errors.country && t('establishmentModal.countryMandatory')}
          variant={errors.country ? 'danger' : 'default'}
          placeholder={t('userForm.choose')}
          onChange={(newValue: string) => handleSelect(newValue, 'country')}
          onBlur={handleBlur}
          className={styles['create-edit-laboratory__form__dropdown']}
          data-cy={'create-edit-laboratory-countries-dropdown'}
        />
        <div className="form__submit-button form__submit-button--right">
          <Button
            label={t(`${establishmentId ? 'action.update' : 'action.create'}`, { ns: 'common' })}
            isLoading={isLoadingUpdate || isLoadingCreate}
            type="submit"
            data-cy={'create-edit-laboratory-submit'}
          />
        </div>
      </form>
    </Box>
  );
};

export default CreateEditLaboratoryForm;
