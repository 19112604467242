import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  Button,
  ButtonSplit,
  IconButton,
  TabItem,
  Tabs,
  Text,
  Chips,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import styles from './order-detail.module.scss';
import DetailTab from './tabs/detail-tab/DetailTab';
import { useTranslation } from 'react-i18next';
import { Order, OrderFile } from '../../../models/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { downloadFile, loadOrderFilesData } from '../../../features/file-manager/file.utils';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getOrderDetailsPagePermissionsSelector } from '../../../store/auth/permissions.selectors';
import { useLazyGetOneOrderQuery } from '../../../services/orders.services';
import { formatDate, splitOrderNumber } from '../../../utils/utils';
import { useGetAllDentistsQuery, useGetLabUsersQuery } from '../../../services/users-rtkq.services';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../../../services/files-api.services';
import Comments from '../../../features/comments/Comments';
import HistoryTab from './tabs/history/HistoryTab';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../enum/feedback';
import { WorkflowPostModelingStepEnum, WorkflowStepEnum } from '../../../enum/workflow-step.ts';
import {
  useLazyGetOneManufacturingOrderQuery,
  useLazyGetProductionFilesQuery,
  useLazyGetProductionZipFileQuery,
  useLazyGetQualityControlZipFileQuery
} from '../../../services/manufacturing-orders-rtkq.services.tsx';
import { ProductionFileUrl, QualityControlFileUrl } from '../../../models/manufacturing-order.tsx';
import { isOrderChairSide } from '../../../utils/order.utils.ts';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import { useFiles } from '../../../hooks/useFiles.tsx';

const OrderDetailPage = () => {
  const { t } = useTranslation(['orderDetail']);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { orderNumber } = useParams();
  const [getOneOrder, { data: orderData, isLoading: isOrderLoading }] = useLazyGetOneOrderQuery();
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const [getProductionZipFile, { isError: isErrorGetProductionZipFile }] =
    useLazyGetProductionZipFileQuery();
  const [getQualityControlZipFile, { isError: isErrorGetQualityControlZipFile }] =
    useLazyGetQualityControlZipFileQuery();
  const [getOneManufacturingOrder] = useLazyGetOneManufacturingOrderQuery();
  const [getProductionFiles] = useLazyGetProductionFilesQuery();
  const connectedUserPermissions = useAppSelector(getOrderDetailsPagePermissionsSelector);

  const { zipProductionFiles } = useFiles();

  // check if we came to this page from the dashboard
  const isDashboard = location.pathname.includes('dashboard');

  const [order, setOrder] = useState<Order | undefined>();
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);

  const downloadChairsideProductionFiles = (orderData: Order) => {
    getOneManufacturingOrder(orderData.orderNumber)
      .unwrap()
      .then((manufacturingOrder) => {
        getProductionFiles({
          orderNumber: orderData.orderNumber,
          patientReference: orderData.patient!.reference!
        })
          .unwrap()
          .then((productionFiles) => {
            zipProductionFiles(manufacturingOrder, productionFiles).finally(() => {
              document.body.style.cursor = 'default';
              setIsDownloadLoading(false);
            });
          });
      });
  };

  const handleDownloadFiles = (isProductionZip: boolean): void => {
    if (!orderData) return;

    setIsDownloadLoading(true);
    document.body.style.cursor = 'wait';
    dispatch(
      feedbackActions.setToast({
        message: t('fileDownload.pending'),
        type: ToastType.INFO
      })
    );
    if (isProductionZip && isOrderChairSide(orderData)) {
      downloadChairsideProductionFiles(orderData);
      return;
    }
    const fetchZipFile = isProductionZip ? getProductionZipFile : getQualityControlZipFile;

    fetchZipFile(orderData.orderNumber)
      .unwrap()
      .then((zipFileUrl) => {
        if (!zipFileUrl) {
          dispatch(
            feedbackActions.setToast({
              message: t(
                isProductionZip
                  ? 'fileDownload.noProductionFiles'
                  : 'fileDownload.noQualityControlFiles'
              ),
              type: ToastType.INFO
            })
          );
          return;
        }

        // put the url depending on the return if isProductionZip or quality control
        const fileUrl = isProductionZip
          ? (zipFileUrl as ProductionFileUrl).downloadUrl
          : (zipFileUrl as QualityControlFileUrl)?.downloadLink;

        if (!fileUrl) {
          dispatch(
            feedbackActions.setToast({
              message: t(
                isProductionZip
                  ? 'fileDownload.noProductionFiles'
                  : 'fileDownload.noQualityControlFiles'
              ),
              type: ToastType.INFO
            })
          );
          return;
        }

        downloadFromStorage({ url: fileUrl as string })
          .unwrap()
          .then((blobFile) => {
            downloadFile({
              extension: 'zip',
              fileName: isProductionZip
                ? `production-${orderData.orderNumber}`
                : `QC-${orderData.orderNumber}`,
              mimeType: 'application/zip',
              data: new File([blobFile], `${orderData.orderNumber}.zip`, {
                type: 'application/zip'
              })
            });
          });

        dispatch(feedbackActions.resetToast());
      })
      .catch(() => {
        dispatch(
          feedbackActions.setToast({
            message: t(
              isProductionZip
                ? 'fileDownload.noProductionFiles'
                : 'fileDownload.noQualityControlFiles'
            ),
            type: ToastType.DANGER
          })
        );
      })
      .finally(() => {
        document.body.style.cursor = 'default';
        setIsDownloadLoading(false);
      });
  };

  const optionsButtonSplit = [
    {
      label: t('downloadOptions.qualityControlFiles'),
      value: 'qualityControlFiles',
      onActionClick: () => handleDownloadFiles(false)
    },
    {
      label: t('downloadOptions.productionFiles'),
      value: 'productionFiles',
      onActionClick: () => handleDownloadFiles(true)
    }
  ];

  const downloadFilesButtonSplit = (
    <ButtonSplit
      data={optionsButtonSplit}
      label={t('actions.download')}
      iconLeft="fa-download"
      className={styles['order-detail__content__header__actions--not-first']}
      size="s"
      isDisabled={isOrderLoading || orderData?.currentStep !== WorkflowStepEnum.DELIVERED}
      isLoading={isDownloadLoading}
      data-cy="button-download"
    />
  );

  const downloadFilesButton = (
    <Button
      label={t('actions.downloadProductionFiles')}
      iconLeft="fa-download"
      className={styles['order-detail__content__header__actions--not-first']}
      size="s"
      isDisabled={
        isOrderLoading ||
        !Object.values(WorkflowPostModelingStepEnum).includes(
          orderData?.currentStep as WorkflowPostModelingStepEnum
        )
      }
      isLoading={isDownloadLoading}
      onClick={() => handleDownloadFiles(true)}
      data-cy="button-download"
    />
  );

  const tabItems: ReactElement[] = [];
  if (connectedUserPermissions.canViewDetail) {
    tabItems.push(
      <TabItem
        key="detail"
        label={t('tabs.detail.title')}
        className={styles['order-detail__content__tab__content']}
        data-cy="detail-tab">
        {order && <DetailTab order={order} />}
      </TabItem>
    );
  }
  if (connectedUserPermissions.canViewComments) {
    tabItems.push(
      <TabItem
        key="comments"
        label={t('tabs.comments.title')}
        className={styles['order-detail__content__tab__content']}
        counter={order?.comments?.length}>
        {orderNumber && <Comments orderNumber={orderNumber} inSidebar={false} />}
      </TabItem>
    );
  }
  if (connectedUserPermissions.canViewHistory) {
    tabItems.push(
      <TabItem
        key="history"
        label={t('tabs.history.title')}
        className={styles['order-detail__content__tab__content']}>
        {connectedUserPermissions.canViewHistory && orderNumber && (
          <HistoryTab orderNumber={orderNumber} />
        )}
      </TabItem>
    );
  }

  // Load users lab in order to have data already loaded for Comment and history tab.
  useGetLabUsersQuery();
  // Load dentist in order to have data already loaded for History tab.
  useGetAllDentistsQuery();

  // If file not found
  useEffect(() => {
    if (isErrorGetProductionZipFile) {
      dispatch(
        feedbackActions.setToast({
          message: t('fileDownload.noProductionFiles'),
          type: ToastType.DANGER
        })
      );
    }
    if (isErrorGetQualityControlZipFile) {
      dispatch(
        feedbackActions.setToast({
          message: t('fileDownload.noQualityControlFiles'),
          type: ToastType.DANGER
        })
      );
    }
  }, [isErrorGetProductionZipFile, isErrorGetQualityControlZipFile]);

  useEffect(() => {
    dispatch(ordersActions.resetOrder());
    if (orderNumber) getOneOrder(orderNumber);

    return () => {
      dispatch(ordersActions.resetOrder());
    };
  }, []);

  useEffect(() => {
    if (orderData && orderNumber) {
      setOrder(orderData);
      loadOrderFilesData(
        dispatch,
        getOneDownloadableFile,
        downloadFromStorage,
        orderNumber,
        orderData.patient?.diagnostic?.patientFiles as OrderFile[]
      );
    }
  }, [orderData]);

  const orderNumberSplit = orderNumber ? splitOrderNumber(orderNumber) : undefined;

  return (
    orderNumberSplit &&
    orderNumber && (
      <div>
        <BreadcrumbsBar>
          {isDashboard ? (
            <BreadcrumbItem
              text={t('link.dashboard', { ns: 'common' })}
              link="/"
              data-cy="dashboard-redirection"
            />
          ) : (
            <BreadcrumbItem
              text={t('link.orders', { ns: 'common' })}
              link="/orders"
              data-cy="orders-redirection"
            />
          )}
          <BreadcrumbItem link="#" text={t('title')} />
        </BreadcrumbsBar>
        <div className={styles['order-detail']}>
          <div className={styles['order-detail__content']}>
            <header className={styles['order-detail__content__header']}>
              <div className={styles['order-detail__content__header__title']}>
                {order?.isInError && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className={styles['order-detail__content__header__title--error']}
                    data-cy="order_detail_title_error"
                  />
                )}
                <Text
                  type="title"
                  label={orderNumberSplit.beforeLastPart}
                  size="s"
                  color={order?.isInError ? 'danger' : undefined}
                  data-cy="order_detail_title"
                />
                <Text
                  type="title"
                  label={orderNumberSplit.lastPart}
                  bold
                  size="s"
                  color={order?.isInError ? 'danger' : undefined}
                  data-cy="order_detail_title"
                />
                <div className={styles['order-detail__content__header__title__status']}>
                  <Chips
                    firstLabel={t(order?.deletedAt ? 'Supprimée' : 'Active', {
                      ns: 'common'
                    })}
                    secondLabel={order?.deletedAt ? formatDate(new Date(order?.deletedAt)) : ''}
                    color={order?.deletedAt ? ColorPropsEnum.DANGER : ColorPropsEnum.SUCCESS}
                  />
                </div>

                <IconButton
                  iconSize={'sm'}
                  faIconClass="copy"
                  iconStyle="regular"
                  onClick={() => {
                    navigator.clipboard.writeText(orderNumber);
                    dispatch(
                      feedbackActions.setToast({
                        message: t('toast.copiedToClipboard', {
                          orderNumber: orderNumber,
                          ns: 'orders'
                        }),
                        type: ToastType.SUCCESS
                      })
                    );
                  }}
                  radius="full"
                />
              </div>
              {orderData && isOrderChairSide(orderData) && (
                <div className={styles['order-detail__content__header__actions']} data-cy="actions">
                  {orderData?.currentStep === WorkflowStepEnum.DELIVERED ? (
                    downloadFilesButtonSplit
                  ) : (
                    <Tooltip>
                      <TooltipContent>{t('fileDownload.noProductionFiles')}</TooltipContent>
                      {downloadFilesButtonSplit}
                    </Tooltip>
                  )}
                </div>
              )}
              {orderData && !isOrderChairSide(orderData) && (
                <div className={styles['order-detail__content__header__actions']} data-cy="actions">
                  {orderData?.currentStep === WorkflowStepEnum.DELIVERED ? (
                    downloadFilesButton
                  ) : (
                    <Tooltip>
                      <TooltipContent>{t('fileDownload.noProductionFiles')}</TooltipContent>
                      {downloadFilesButton}
                    </Tooltip>
                  )}
                </div>
              )}
            </header>
            <Tabs
              activeTabId={0}
              data-cy="order-detail-tabs"
              id="order-detail-tabs"
              className={styles['order-detail__content__tab']}
              operatingMode="index">
              {tabItems.map((tabItem) => tabItem)}
            </Tabs>
          </div>
        </div>
      </div>
    )
  );
};

export default OrderDetailPage;
