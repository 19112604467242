import { useEffect, useState } from 'react';
import { Box, Button, Dropdown, Text } from '@platform-storybook/circlestorybook';
import { Address, Establishment } from '../../../../../../models/establishment.tsx';
import { AdminRole, Country } from '../../../../../../enum/user.ts';
import { UserInfo } from '../../../../../../models/user.tsx';
import i18next from 'i18next';
import {
  isClinic,
  isDentistRole,
  isDesignCenter,
  isDesignerRole,
  isLaboratory,
  mapForDropdown
} from '../../../../../../utils/utils.tsx';
import styles from './user-forms.module.scss';
import { ColorPropsEnum } from '../../../../../../enum/color.enum.ts';
import { useGetUserQuery } from '../../../../../../services/users-rtkq.services.tsx';
import {
  useGetAllClinicsQuery,
  useGetAllDesignCentersQuery,
  useGetAllLaboratoriesQuery
} from '../../../../../../services/establishments.services.ts';

type Props = {
  email: string;
  establishment: Establishment;
  establishmentApiKeyBack: string;
  saveCallback: (user: Partial<UserInfo>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const EstablishmentForm = ({
  email,
  establishment,
  establishmentApiKeyBack,
  saveCallback,
  closeSideNavCallback
}: Props) => {
  // this will not refetch, it just reselects
  const { data: user } = useGetUserQuery(email);
  const {
    data: clinics,
    isLoading: isClinicsLoadingLoading,
    isSuccess: isSuccessGetAllClinics
  } = useGetAllClinicsQuery();
  const {
    data: laboratories,
    isLoading: isLaboratoriesLoading,
    isSuccess: isSuccessGetAllLaboratories
  } = useGetAllLaboratoriesQuery();
  const {
    data: designCenters,
    isLoading: isDesignCentersLoading,
    isSuccess: isSuccessGetAllDesignCenters
  } = useGetAllDesignCentersQuery();

  const clinicList = clinics?.data ?? [];
  const laboratoryList = laboratories?.data ?? [];
  const designCenterList = designCenters?.data ?? [];

  const [name, setName] = useState<string>(establishment?.name || '');
  const [address, setAddress] = useState<string>(establishment?.address?.address || '');
  const [additionalAddress, setAdditionalAddress] = useState<string>(
    establishment?.address?.additionalAddress || ''
  );
  const [zipCode, setZipCode] = useState<string>(establishment?.address?.zipCode || '');
  const [city, setCity] = useState<string>(establishment?.address?.city || '');
  const [country, setCountry] = useState<Country | undefined>(establishment?.address?.country);
  const [region, setRegion] = useState<string>(establishment?.address?.region || '');
  const [selectedClinicId, setSelectedClinicId] = useState<number | undefined>(undefined);
  const [selectedDesignCenterId, setSelectedDesignCenterId] = useState<number | undefined>();
  const [selectedLaboratoryId, setSelectedLaboratoryId] = useState<number | undefined>(undefined);
  const [isEstablishmentMandatory, setIsEstablishmentMandatory] = useState(false);

  const clinicMandatory = i18next.t('userForm.clinicMandatory', { ns: 'user' });
  const designCenterMandatory = i18next.t('userForm.designCenterMandatory', { ns: 'user' });
  const laboratoryMandatory = i18next.t('userForm.laboratoryMandatory', { ns: 'user' });

  const isDisplayDentistFields = user?.role ? isDentistRole(user.role) : false;
  const isDisplayDesignCenterFields = user?.role ? isDesignerRole(user.role) : false;

  useEffect(() => {
    if (isSuccessGetAllClinics && isDisplayDentistFields && user?.clinic && clinicList.length > 0) {
      setSelectedClinicId(establishment.id);
    }
  }, [isSuccessGetAllClinics]);

  useEffect(() => {
    if (
      isSuccessGetAllDesignCenters &&
      isDisplayDesignCenterFields &&
      user?.designCenter &&
      designCenterList.length > 0
    ) {
      setSelectedDesignCenterId(establishment.id);
    }
  }, [isSuccessGetAllDesignCenters]);

  useEffect(() => {
    if (isSuccessGetAllLaboratories) {
      if (user?.laboratory && laboratoryList.length > 0) {
        setSelectedLaboratoryId(establishment.id);
      }
    }
  }, [isSuccessGetAllLaboratories]);

  const onSelectDesignCenter = (id: number) => {
    setSelectedDesignCenterId(id || undefined);
    updateEstablishment(designCenterList.find((ets) => ets.id === id));
    setIsEstablishmentMandatory(!id);
  };

  const onSelectClinic = (id: number): void => {
    setSelectedClinicId(id || undefined);
    updateEstablishment(clinicList.find((ets) => ets.id === id));
    setIsEstablishmentMandatory(!id);
  };

  const onSelectLaboratory = (id: number): void => {
    setSelectedLaboratoryId(id || undefined);
    updateEstablishment(laboratoryList.find((ets) => ets.id === id));
    setIsEstablishmentMandatory(!id);
  };

  const updateEstablishment = (establishment: Establishment | undefined): void => {
    setName(establishment?.name || '');
    setAddress(establishment?.address?.address || '');
    setAdditionalAddress(establishment?.address?.additionalAddress || '');
    setZipCode(establishment?.address?.zipCode || '');
    setCity(establishment?.address?.city || '');
    setRegion(establishment?.address?.region || '');
    setAddress(establishment?.address?.address || '');
    setCountry(establishment?.address?.country || undefined);
  };

  const establishmentId = (): number | undefined => {
    if (establishment?.type && isClinic(establishment.type)) return selectedClinicId;
    if (establishment?.type && isLaboratory(establishment.type)) return selectedLaboratoryId;
    if (establishment?.type && isDesignCenter(establishment.type)) return selectedDesignCenterId;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!establishmentId()) {
      setIsEstablishmentMandatory(true);
      return;
    }

    establishment = {
      ...establishment,
      id: establishmentId(),
      name: name,
      address: address
        ? ({
            address: address || undefined,
            additionalAddress: additionalAddress || undefined,
            zipCode: zipCode || undefined,
            city: city || undefined,
            country: country || undefined,
            region: region || undefined
          } as Address)
        : undefined
    };

    const paramsToUpdate =
      user?.role &&
      establishment?.type &&
      isDentistRole(user.role) &&
      isLaboratory(establishment.type)
        ? {
            [establishmentApiKeyBack]: {
              id: establishment.id
            }
          }
        : {
            [establishmentApiKeyBack]: {
              id: establishment.id
            }
          };
    if (user?.email) saveCallback(paramsToUpdate, user.email);
    closeSideNavCallback(false);
  };

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        {user?.role !== AdminRole.ADMIN ? (
          <>
            {establishment?.type && isLaboratory(establishment.type) && (
              <Dropdown
                data={isLaboratoriesLoading ? [] : [mapForDropdown(laboratoryList)]}
                value={selectedLaboratoryId}
                data-cy="dropdown-laboratory"
                placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                onChange={(newValue: number) => onSelectLaboratory(+newValue)}
                helperText={isEstablishmentMandatory ? laboratoryMandatory : undefined}
                variant={isEstablishmentMandatory ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['user-forms__input']}
              />
            )}
            {establishment?.type && isDesignCenter(establishment.type) && (
              <Dropdown
                data={isDesignCentersLoading ? [] : [mapForDropdown(designCenterList)]}
                value={selectedDesignCenterId}
                placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                onChange={(newValue: number) => onSelectDesignCenter(+newValue)}
                helperText={isEstablishmentMandatory ? designCenterMandatory : undefined}
                variant={isEstablishmentMandatory ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['user-forms__input']}
              />
            )}
            {establishment?.type && isClinic(establishment.type) && (
              <Dropdown
                isSearchable={true}
                data-cy="dropdown-clinic"
                data={isClinicsLoadingLoading ? [] : [mapForDropdown(clinicList)]}
                value={selectedClinicId}
                placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                onChange={(newValue: number) => onSelectClinic(+newValue)}
                helperText={isEstablishmentMandatory ? clinicMandatory : undefined}
                variant={isEstablishmentMandatory ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['user-forms__input']}
              />
            )}
            {name && (
              <div className={styles['user-forms__input']}>
                <Text data-cy="establishment-name" label={name}></Text>
                {address && (
                  <>
                    <Text label={address}></Text>
                    {additionalAddress && <Text label={additionalAddress}></Text>}

                    <Text label={zipCode + ' ' + city + ' ' + (region || '')}></Text>
                    <Text
                      label={
                        country && Object.values(Country).includes(country)
                          ? i18next.t(`countries.${country.toLowerCase()}`, {
                              ns: 'common'
                            })
                          : ''
                      }></Text>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <Text label={i18next.t('establishmentModal.theEnd', { ns: 'user' })} />
        )}

        <div className="form__submit-button">
          <Button
            label={i18next.t('action.update', { ns: 'common' })}
            data-cy="user-forms-establishment-submit"
            type="submit"
          />
        </div>
      </form>
    </Box>
  );
};

export default EstablishmentForm;
