import { api } from './api';
import { Partner, PartnerForCreation } from '../models/partner.tsx';

export const partnersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPartners: builder.query({
      query: (arg: { page: number; limit: number; filters: string; sort: string }) => {
        const { page, limit, filters, sort } = arg;
        return `/partners?page=${page}&filter.items.deletedAt=$null&limit=${limit}${filters}${sort}`;
      }
    }),
    createPartner: builder.mutation<Partner, PartnerForCreation>({
      query: (partner) => ({
        url: '/partners',
        method: 'POST',
        body: partner
      })
    }),
    patchPartner: builder.mutation<Partner, Partner>({
      query: ({ code, ...patch }: Partner) => ({
        url: `/partners/${code}`,
        method: 'PATCH',
        body: patch
      })
    })
  })
});

export const { useLazyGetPartnersQuery, useCreatePartnerMutation, usePatchPartnerMutation } =
  partnersApi;
