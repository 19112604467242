import React, { useEffect, useState } from 'react';
import { StringObject, UnknownObject } from '../models/common';
import { CheckboxItem, DropdownItem } from '../models/form';

/**
 *
 * @param initialValues
 * @param submitCallback
 * @param validateCallback give validation callback if you need custom validation (by default all field are required)
 * @returns
 */

export default function useForm(
  initialValues: UnknownObject,
  submitCallback: () => void | Promise<void>,
  validateCallback?: (values: UnknownObject) => StringObject
) {
  const [values, setValues] = useState<UnknownObject>(initialValues);
  const [errors, setErrors] = useState<StringObject>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitCallback();
    }
  }, [errors]);

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  // TEXTFIELD COMPONENT
  const handleChange = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    setValues((values) => ({ ...values, [target.name]: target.value }));
  };

  const handleBlur = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    setErrors((errors) => ({ ...errors, [target.name]: undefined }));
  };

  // DROPDOWN & RADIOLIST COMPONENT
  const handleSelect = (newValue: string | number, name: string) => {
    setValues((values) => ({ ...values, [name]: newValue }));
    setErrors((errors) => ({ ...errors, [name]: undefined }));
  };

  // DROPDOWN MULTICHOICE COMPONENT
  const handleMultiSelect = (selectedValue: string | number, name: string) => {
    let updatedValues: Array<object>;
    if (selectedValue) {
      updatedValues = (values[name] as Array<Array<CheckboxItem>>).map(
        (data: Array<DropdownItem>) => {
          return data.map((data) => {
            if (data.value === selectedValue && !data.isDisabled) {
              return {
                ...data,
                isChecked: !data.isChecked
              };
            } else {
              return data;
            }
          });
        }
      );
    } else {
      updatedValues = (values[name] as Array<Array<CheckboxItem>>).map(
        (data: Array<DropdownItem>) =>
          data.map((data: DropdownItem) => ({ ...data, isChecked: false }))
      );
    }

    setValues((values) => ({ ...values, [name]: updatedValues }));
    setErrors((errors) => ({ ...errors, [name]: undefined }));
  };

  // CHECKBOX COMPONENT
  const handleCheck = (id: string | number, name: string) => {
    const updatedValues = (values[name] as Array<CheckboxItem>).map((val: CheckboxItem) => {
      if (val.value === id) {
        return {
          ...val,
          isChecked: !val.isChecked
        };
      } else {
        return val;
      }
    });
    setValues((values) => ({ ...values, [name]: updatedValues }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    if (event) event.preventDefault();
    const newErrors = validateCallback ? validateCallback(values) : validateAllFieldsRequired();
    setErrors(newErrors);
    setIsSubmitting(true);
  };

  return {
    handleMultiSelect,
    handleChange,
    handleSubmit,
    handleBlur,
    handleSelect,
    handleCheck,
    setValues,
    values,
    errors
  };
}
