import { TypeColWithNamePropertyPlatform } from '../../models/datagrid.tsx';
import { getColumnOptions } from './datagrid.utils.ts';
import i18next from 'i18next';
import { Chips, Tooltip, TooltipContent } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import moment from 'moment';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';

export const colDeletedAt: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'deletedAt',
    i18next.t('products.datagrid.headers.status', { ns: 'catalog' }),
    1,
    false
  ),
  maxWidth: 125,
  filterEditor: SelectFilter,
  filterEditorProps: {
    dataSource: [
      { id: 'active', label: i18next.t('active', { ns: 'common' }) },
      { id: 'inactive', label: i18next.t('inactive', { ns: 'common' }) }
    ]
  },
  render({ value }) {
    const chip = (
      <Chips
        firstLabel={i18next.t(value ? 'inactive' : 'active', { ns: 'common' })}
        color={value ? ColorPropsEnum.WARNING : ColorPropsEnum.SUCCESS}
      />
    );
    return value ? (
      <Tooltip>
        <TooltipContent>
          {moment(value).format(i18next.t('date.full', { ns: 'common' }))}
        </TooltipContent>
        {chip}
      </Tooltip>
    ) : (
      chip
    );
  }
};
