import i18next from 'i18next';
import { PrivateAppPermissions } from '../../models/connected-user.tsx';

/**
 * Return main menu entries according to the connected user permissions
 * @param privateAppUserPermissions
 * @param connectedUser
 */
export const getLeftMenuEntries = (privateAppUserPermissions: PrivateAppPermissions) => {
  const allowedEntries = [];

  if (privateAppUserPermissions.canAccessDashboard) {
    allowedEntries.push({
      label: i18next.t('link.dashboard', { ns: 'common' }),
      type: 'nav',
      link: '/dashboard/cloud-manager',
      iconClass: 'fa-gauge'
    });
  }

  if (privateAppUserPermissions.canAccessOrders) {
    allowedEntries.push({
      label: i18next.t('link.orders', { ns: 'common' }),
      type: 'nav',
      link: '/orders',
      iconClass: 'table-list'
    });
  }

  if (privateAppUserPermissions.canAccessUsers) {
    allowedEntries.push({
      label: i18next.t('link.users', { ns: 'common' }),
      type: 'nav',
      link: '/main-users/users',
      iconClass: 'fa-users'
    });
  }

  if (privateAppUserPermissions.canAccessCatalog) {
    allowedEntries.push({
      label: i18next.t('link.catalog', { ns: 'common' }),
      type: 'nav',
      link: '/catalog/products',
      iconClass: 'fa-book'
    });
  }

  return [allowedEntries];
};
