import { useState } from 'react';
import { BreadcrumbItem, BreadcrumbsBar, Divider, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import styles from './user-details.module.scss';
import { EstablishmentPayload } from '../../enum/establishment';
import { ColorPropsEnum } from '../../enum/color.enum';
import { useGetUserQuery } from '../../services/users-rtkq.services';
import { getUserManagementPermissionsSelector } from '../../store/auth/permissions.selectors.tsx';
import PersonalInfoSection from './PersonalInfoSection.tsx';
import EstablishmentSection from './EstablishmentSection.tsx';
import CustomizationSection from './CustomizationSection.tsx';

type Props = {
  isReadOnly: boolean;
  email: string;
};

const UserDetails = ({ isReadOnly, email }: Props) => {
  const { t } = useTranslation(['user']);
  const { data: user, isLoading } = useGetUserQuery(email);

  const connectedUserPermissions = useAppSelector(getUserManagementPermissionsSelector);
  const [isOpenedLaboratory, setIsOpenedLaboratory] = useState(false);
  const [isOpenedClinic, setIsOpenedClinic] = useState(false);
  const [isOpenedDesignCenter, setIsOpenedDesignCenter] = useState(false);

  return (
    user && (
      <>
        {isReadOnly && (
          <BreadcrumbsBar className={styles['user-detail__breadcrumb']}>
            <BreadcrumbItem link="/main-users/users" text={t('tabs.users')} />
            <BreadcrumbItem
              link={`/users/${user?.email}/detail`}
              text={t('userForm.titleDetail')}
            />
          </BreadcrumbsBar>
        )}
        <div className={styles['user-detail__content']}>
          {/* PersonalInfoSection */}
          <PersonalInfoSection user={user} isLoading={isLoading} isReadOnly={isReadOnly} />
          <Divider />

          {!isLoading && user?.isAllowedToOrder && (
            <>
              <section className={styles['user-detail__content__section']}>
                <header className={styles['user-detail__content__section__header']}>
                  <Text
                    label={t('userForm.statistics')}
                    size="s"
                    className={styles['user-detail__content__section__header__title']}
                    type="title"
                  />
                </header>
                <div>
                  <div>
                    <div>
                      <Text color={ColorPropsEnum.GREY} label={t('userForm.ordersNumber')}></Text>
                      <Text label="15"></Text>
                    </div>
                  </div>
                </div>
              </section>
              <Divider />
            </>
          )}

          {/* Establishments section */}
          {!isLoading && user?.clinic && Object.keys(user?.clinic).length > 0 && (
            <EstablishmentSection
              establishment={user.clinic}
              establishmentApiKeyBack={EstablishmentPayload.CLINIC}
              stateSideBar={{ setState: setIsOpenedClinic, getState: isOpenedClinic }}
              user={user}
              isLoading={isLoading}
              isReadOnly={isReadOnly}
            />
          )}
          {!isLoading && user?.laboratory && Object.keys(user?.laboratory).length > 0 && (
            <EstablishmentSection
              establishment={user.laboratory}
              establishmentApiKeyBack={EstablishmentPayload.LABORATORY}
              stateSideBar={{ setState: setIsOpenedLaboratory, getState: isOpenedLaboratory }}
              allowEdit={connectedUserPermissions.canEditEstablishment}
              user={user}
              isLoading={isLoading}
              isReadOnly={isReadOnly}
            />
          )}
          {!isLoading && user?.designCenter && Object.keys(user?.designCenter).length > 0 && (
            <EstablishmentSection
              establishment={user.designCenter}
              establishmentApiKeyBack={EstablishmentPayload.DESIGN_CENTER}
              stateSideBar={{ setState: setIsOpenedDesignCenter, getState: isOpenedDesignCenter }}
              user={user}
              isLoading={isLoading}
              isReadOnly={isReadOnly}
            />
          )}
          <CustomizationSection user={user} isLoading={isLoading} isReadOnly={isReadOnly} />
        </div>
      </>
    )
  );
};

export default UserDetails;
