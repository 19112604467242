import { useCallback, useEffect, useState } from 'react';
import styles from '../create-product-page.module.scss';
import { Box, Button, Chips, Dialog, Text } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  circleSoftwareCompatibilitySelector,
  productSelector
} from '../../../../../../store/products/products.selectors';
import { useTranslation } from 'react-i18next';
import { getValue } from '../../../components-page/components';
import { feedbackActions } from '../../../../../../store/feedback/feedback.reducer';
import { getLocalizedProperty, getMessageError } from '../../../../../../utils/utils';
import { createProduct } from '../../../../../../services/products.services';
import { CreateProductNavProps } from '../CreateProductPage';
import { getComponentListItemSupportingText } from '../../product';
import { InjectionModeEnum } from '../../../../../../enum/product.enum';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { ToastType } from '../../../../../../enum/feedback';

const Summary = ({ previousStepCallback, submitCallback }: CreateProductNavProps) => {
  const product = useAppSelector(productSelector);
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const productCompatibilitiesLength = product?.productCompatibilities
    ? product.productCompatibilities.length
    : 0;
  const circleSoftwareCompatibility = useAppSelector(circleSoftwareCompatibilitySelector);

  const handleEscKey = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsDialogOpened(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  const handleSubmit = () => {
    setIsDialogOpened(true);
  };

  const addNewProduct = async () => {
    const newProduct = {
      ...product,
      class: product?.class || undefined,
      category: product?.category || undefined,
      subCategories: (product?.subCategories?.[0] || [])
        .filter((subCategory) => subCategory.isChecked)
        .map((subCategory) => subCategory.value),
      circleCadVersion: product?.circleCadVersion || undefined,
      components: product?.components?.map((component) => {
        return { id: component.id, rule: component.rule };
      })
    };

    try {
      setIsLoading(true);
      await createProduct(newProduct);
      if (submitCallback) submitCallback();
    } catch (e) {
      dispatch(feedbackActions.setToast({ message: getMessageError(e), type: ToastType.DANGER }));
    } finally {
      setIsLoading(false);
      setIsDialogOpened(false);
    }
  };

  return (
    <>
      <Dialog
        title={t('products.productForm.createProduct.dialog', {
          label: getValue(product, getLocalizedProperty('label'))
        })}
        isOpened={isDialogOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('products.productForm.createProduct.confirm')}
        onCancel={() => setIsDialogOpened(false)}
        onConfirm={addNewProduct}
        isLoading={isLoading}
      />
      <form className={styles['create-product-page__form']}>
        <Box className={styles['create-product-page__box--scrollable']}>
          <>
            <div className={styles['create-product-page__summary__text']}>
              <Text label={t('products.productForm.labelFr.label')} color={ColorPropsEnum.GREY} />
              <Text label={product?.labelFr} />
            </div>
            <div className={styles['create-product-page__summary__text']}>
              <Text label={t('products.productForm.labelEn.label')} color={ColorPropsEnum.GREY} />
              <Text label={product?.labelEn} />
            </div>
            <div className={styles['create-product-page__summary__text']}>
              <Text label={t('products.productForm.family.label')} color={ColorPropsEnum.GREY} />
              <Chips
                firstLabel={t(`products.families.${product?.family}`)}
                secondLabel={product?.class ? t(`products.classes.${product.class}`) : undefined}
                color={product?.family ? `family-${product.family.toLowerCase()}` : 'primary'}
                size="m"></Chips>
            </div>
            <div className={styles['create-product-page__summary__text']}>
              <Text
                label={t('products.productForm.circleCadVersion.detailLabel')}
                color={ColorPropsEnum.GREY}
              />
              {product?.circleCadVersion && (
                <Chips firstLabel={product.circleCadVersion} category="secondary" size="m"></Chips>
              )}
              {!product?.circleCadVersion && (
                <Text label={t('products.productForm.circleCadVersion.noData')} />
              )}
            </div>
            <div
              className={styles['create-product-page__summary__text']}
              data-cy="product-form__summary__circleSoftwareCompatibility">
              <Text
                label={t('products.productForm.circleSoftwareCompatibility.label')}
                color={ColorPropsEnum.GREY}
              />
              <Text
                label={t(
                  `products.productForm.circleSoftwareCompatibility.${circleSoftwareCompatibility.toLowerCase()}`
                )}
              />
            </div>
            {product?.circleCadProductType && (
              <div className={styles['create-product-page__summary__text']}>
                <Text
                  label={t('products.productForm.circleCadProductType.label')}
                  color={ColorPropsEnum.GREY}
                />

                <Chips
                  firstLabel={product?.circleCadProductType}
                  category="secondary"
                  size="m"></Chips>
              </div>
            )}
            <div className={styles['create-product-page__summary__text']}>
              <Text
                label={t('products.productForm.category.summaryLabel')}
                color={ColorPropsEnum.GREY}
              />
              {product?.category && <Text label={t(`products.categories.${product.category}`)} />}
              {!product?.category && <Text label={t('products.productForm.noSelection')} />}
            </div>
            <div
              className={styles['create-product-page__summary__text']}
              data-cy="product-form__summary__sub-categories">
              <Text
                label={t('products.productForm.subCategories.summaryLabel')}
                color={ColorPropsEnum.GREY}
              />
              {product?.subCategories ? (
                product?.subCategories[0].map(
                  (subCategory) =>
                    subCategory.isChecked && (
                      <Text key={subCategory.value} label={subCategory.label} />
                    )
                )
              ) : (
                <Text label={t('products.productForm.noSelection')} />
              )}
            </div>
            {product?.components?.map((component) => {
              return (
                <div
                  key={`component_${component.id.toString()}`}
                  className={styles['create-product-page__summary__text']}>
                  <Text label={component.label} color={ColorPropsEnum.GREY} />
                  <Text label={getComponentListItemSupportingText(component)} />
                </div>
              );
            })}
            <div className={styles['create-product-page__summary__text']}>
              <Text label={t('products.productForm.teethMode.label')} color={ColorPropsEnum.GREY} />
              <Text label={t(`teethModes.${product?.teethMode}`)} />
            </div>
            <div className={styles['create-product-page__summary__text']}>
              <Text label={t('products.productForm.stumpMode.label')} color={ColorPropsEnum.GREY} />
              <Text label={t(`stumpModes.${product?.stumpMode}`)} />
            </div>
            {product?.injectionMode === InjectionModeEnum.SELECTABLE && (
              <div className={styles['create-product-page__summary__text']}>
                <Text
                  label={t('products.productForm.injectionMode.label')}
                  color={ColorPropsEnum.GREY}
                />
                <Text data-cy="summary-injection" label={t('yes', { ns: 'common' })} />
              </div>
            )}
            <div className={styles['create-product-page__summary__text']}>
              <Text
                label={t('products.productForm.proportionalBilling.title')}
                color={ColorPropsEnum.GREY}
              />
              {product?.proportionalBilling && (
                <Text
                  data-cy="summary-billing-element"
                  label={t('products.productForm.proportionalBilling.label')}
                />
              )}
              {!product?.proportionalBilling && (
                <Text
                  data-cy="summary-billing-product"
                  label={t('products.productForm.proportionalBilling.productBilling')}
                />
              )}
            </div>
            <div className={styles['create-product-page__summary__text']}>
              <Text
                label={t('products.productForm.wizardSteps.rules')}
                color={ColorPropsEnum.GREY}
              />
              {!product?.productRule?.allowSameProductInOrder &&
                product?.productRule?.allowSaneTeethInArch &&
                !product?.productRule?.allowSameProductOnArch && (
                  <Text label={t('products.productForm.summary.noProductRules')} />
                )}
              {product?.productRule?.allowSameProductInOrder && (
                <Text label={t('products.productForm.allowSameProductInOrder.label')} />
              )}
              {product?.productRule?.allowSameProductOnArch && (
                <Text label={t('products.productForm.allowSameProductOnArch.label')} />
              )}
              {!product?.productRule?.allowSaneTeethInArch && (
                <Text label={t('products.productForm.removeSaneTeethInArch.label')} />
              )}
            </div>
            <div className={styles['create-product-page__summary__text']}>
              <Text
                label={t('products.productForm.compatibilities.label')}
                color={ColorPropsEnum.GREY}
              />
              <Text
                data-cy="summary-compatibility"
                label={t('products.productForm.compatibilities.counter', {
                  count: productCompatibilitiesLength
                })}
              />
            </div>
          </>
        </Box>

        <div className="form__buttons">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="outlined"
            onClick={previousStepCallback}
            iconLeft="fa-chevron-left"
          />
          <Button label={t('products.productForm.createProduct.confirm')} onClick={handleSubmit} />
        </div>
      </form>
    </>
  );
};

export default Summary;
