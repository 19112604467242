import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import commonFR from './translations/fr/common.json';
import commonEN from './translations/en/common.json';
import authEN from './translations/en/auth.json';
import authFR from './translations/fr/auth.json';
import userEN from './translations/en/user.json';
import userFR from './translations/fr/user.json';
import errorFR from './translations/fr/error.json';
import errorEN from './translations/en/error.json';
import catalogFR from './translations/fr/catalog.json';
import catalogEN from './translations/en/catalog.json';
import ordersFR from './translations/fr/orders.json';
import ordersEN from './translations/en/orders.json';
import compatibilitiesFR from './translations/fr/compatibilities.json';
import compatibilitiesEN from './translations/en/compatibilities.json';
import pageResetPasswordEN from './translations/en/page_reset-password.json';
import pageResetPasswordFR from './translations/fr/page_reset-password.json';
import componentFR from './translations/fr/component.json';
import componentEN from './translations/en/component.json';
import orderDetailFR from './translations/fr/page-order-detail.json';
import orderDetailEN from './translations/en/page-order-detail.json';
import commentFR from './translations/fr/comment.json';
import commentEN from './translations/en/comment.json';
import fileManagerFR from './translations/fr/feature-file-manager.json';
import fileManagerEN from './translations/en/feature-file-manager.json';
import historyFR from './translations/fr/history.json';
import historyEN from './translations/en/history.json';
import dashboardFR from './translations/fr/page-dashboard.json';
import dashboardEN from './translations/en/page-dashboard.json';

//Creating object with the variables of imported translation files
const resources = {
  fr: {
    common: commonFR,
    auth: authFR,
    user: userFR,
    error: errorFR,
    catalog: catalogFR,
    orders: ordersFR,
    compatibilities: compatibilitiesFR,
    resetPassword: pageResetPasswordFR,
    component: componentFR,
    orderDetail: orderDetailFR,
    fileManager: fileManagerFR,
    history: historyFR,
    comment: commentFR,
    dashboard: dashboardFR
  },
  en: {
    common: commonEN,
    auth: authEN,
    user: userEN,
    error: errorEN,
    catalog: catalogEN,
    orders: ordersEN,
    compatibilities: compatibilitiesEN,
    resetPassword: pageResetPasswordEN,
    component: componentEN,
    orderDetail: orderDetailEN,
    fileManager: fileManagerEN,
    history: historyEN,
    comment: commentEN,
    dashboard: dashboardEN
  }
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr', //default language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
