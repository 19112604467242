import { useCallback, useEffect, useState } from 'react';
import styles from './cloud-manager.module.scss';
import { Button } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.tsx';
import { useTranslation } from 'react-i18next';
import DatagridFeature from '../../../../features/datagrid/DatagridFeature.tsx';
import {
  FilterReducer,
  LoadDataProps,
  ServicePaginatedResponse,
  TypeColWithNamePropertyPlatform
} from '../../../../models/datagrid.tsx';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers.tsx';
import { ordersDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors.tsx';

import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { useGetCommonTypesQuery } from '../../../../services/common-types-rtkq.services.ts';
import DatagridReload from '../../../../features/datagrid/DatagridReload.tsx';
import { getColumnSettings, getFilters, getSort, rowClassname } from '../../orders-page/orders.tsx';
import { useLazyGetOrdersQuery } from '../../../../services/orders.services.tsx';
import { getPrivateAppPermissionsSelector } from '../../../../store/auth/permissions.selectors.tsx';

const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'orders']);
  const datagridSettings = useAppSelector(ordersDatagridStateSelector);
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const [getOrders] = useLazyGetOrdersQuery();
  const [reloadDate, setReloadDate] = useState<Date>();
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const connectedUserPermissions = useAppSelector(getPrivateAppPermissionsSelector);

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);

    return getOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders: ServicePaginatedResponse) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, [isReloadNeeded]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'orders',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    let newSort = newSortInfo as TypeSingleSortInfo;
    // For now, we reset filters at every changes
    if (Array.isArray(newSort)) {
      newSort = newSort[newSort.length - 1];
    }
    dispatch(datagridSettingsActions.setDatagridSort({ datagrid: 'orders', sort: newSort }));
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetOrdersDatagridFilters());
  };

  return (
    <>
      <div className={styles['cloud-manager__actions']} id={'actions-buttons'}>
        <Button
          data-dy="remove-filter-button"
          category="text"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        {reloadDate && (
          <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
        )}
      </div>
      <div className={styles['cloud-manager__content']}>
        {!areCommonTypesLoading && (
          <DatagridFeature
            key="orders"
            style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
            columns={
              [
                ...getColumnSettings(
                  commonTypes,
                  dispatch,
                  connectedUserPermissions.canAccessOrderDetail,
                  true
                )
              ]
                .filter((column) => column !== undefined)
                .filter((column) =>
                  ['orderNumber', 'submissionDate', 'currentStep'].includes(column.name as string)
                ) as TypeColWithNamePropertyPlatform[]
            }
            filterValue={datagridSettings.filters}
            onFilterValueChange={onFilterValueChange}
            sortInfo={datagridSettings.sort}
            onSortInfoChange={onSortInfoChange}
            dataSource={dataSource}
            rowClassName={rowClassname}
            rowHeight={46}
          />
        )}
      </div>
    </>
  );
};

export default DashboardPage;
