import { useCallback, useState } from 'react';
import { Button, SideBarModal } from '@platform-storybook/circlestorybook';
import { FilterReducer, LoadDataProps } from '../../../../models/datagrid.tsx';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.tsx';
import styles from './partners-page.module.scss';
import DatagridFeature from '../../../../features/datagrid/DatagridFeature.tsx';
import { getColumnSettings, getFilters, getSort } from './partners.tsx';
import { useTranslation } from 'react-i18next';
import { partnersDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors.tsx';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers.tsx';
import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { Partner } from '../../../../models/partner.tsx';
import { getUserManagementPermissionsSelector } from '../../../../store/auth/permissions.selectors.tsx';
import CreateEditPartnerForm from './CreateEditPartnerForm.tsx';
import { useLazyGetPartnersQuery } from '../../../../services/partners.services.tsx';

const PartnersPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'establishment']);
  const connectedUserPermissions = useAppSelector(getUserManagementPermissionsSelector);

  const datagridSettings = useAppSelector(partnersDatagridStateSelector);
  const [getPartners] = useLazyGetPartnersQuery();

  const [isSideBarAddEditPartnerOpened, setIsSideBarAddEditPartnerOpened] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [partner, setPartner] = useState<Partner>();

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue!);
    const sort = getSort(sortInfo);

    return getPartners({ page, limit, filters, sort })
      .then((partners) => ({
        data: partners.data.data,
        count: partners.data.meta.totalItems
      }))
      .catch(() => ({ data: [], count: 0 }));
  };

  const dataSource = useCallback(loadData, [isReloading]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'partners',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'partners',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };

  // kebab actions
  const editCallBack = async (partner: Partner) => {
    setPartner(partner);
    setIsSideBarAddEditPartnerOpened(true);
  };

  const handleCloseModal = () => {
    setIsSideBarAddEditPartnerOpened(false);
    setPartner(undefined);
  };

  return (
    <div>
      <div className={styles['partners-page__actions']}>
        <Button
          category="text"
          label={t('datagrid.removeAllFilters')}
          onClick={() => {
            dispatch(datagridSettingsActions.resetPartnersDatagridFilters());
          }}
        />
        {connectedUserPermissions.canCreatePartners && (
          <Button
            data-cy="create-partner-btn"
            iconRight="plus"
            label={t('partnerDataGrid.actions.createPartner', { ns: 'user' })}
            onClick={() => {
              setPartner(undefined);
              setIsSideBarAddEditPartnerOpened(true);
            }}
          />
        )}
      </div>
      <DatagridFeature
        columns={getColumnSettings(editCallBack, connectedUserPermissions)}
        style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
        filterValue={datagridSettings.filters}
        onFilterValueChange={onFilterValueChange}
        sortInfo={datagridSettings.sort}
        onSortInfoChange={onSortInfoChange}
        dataSource={dataSource}
        rowClassName={undefined}
      />

      {isSideBarAddEditPartnerOpened && (
        <SideBarModal
          title={t(`${partner ? 'partnerModal.updatePartner' : 'partnerModal.createPartner'}`, {
            ns: 'user'
          })}
          isOpened={isSideBarAddEditPartnerOpened}
          closeOnOutsideClick={true}
          onClose={() => handleCloseModal()}>
          <CreateEditPartnerForm
            onClose={handleCloseModal}
            partner={partner}
            setIsReloading={setIsReloading}
          />
        </SideBarModal>
      )}
    </div>
  );
};

export default PartnersPage;
