import {
  FilterProps,
  MenuEntry,
  SortDirection,
  SortReducer,
  TypeColWithNamePropertyPlatform
} from '../../../../models/datagrid.tsx';
import i18next from 'i18next';
import { DropdownMenu, IconButton, Link, Text } from '@platform-storybook/circlestorybook';
import moment from 'moment/moment';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import { AdminRole, DentistRole, DesignerRole, LabUserRole } from '../../../../enum/user.ts';
import { UserInfo } from '../../../../models/user.tsx';
import { capitalize, isDentistRole } from '../../../../utils/utils.tsx';
import stylesDataGrid from '../../../../features/datagrid/datagrid-feature.module.scss';
import { EstablishmentPayload } from '../../../../enum/establishment.ts';
import { getColumnOptions } from '../../../../features/datagrid/datagrid.utils.ts';
import { colDeletedAt } from '../../../../features/datagrid/columns.tsx';

const buildMenu = (
  user: UserInfo,
  tagsCallback: (user: UserInfo) => void,
  deactivateCallback: (user: UserInfo) => void,
  resetPasswordCallback: (user: UserInfo) => void,
  setAuthorizationOrderCallback: (user: UserInfo) => void
): MenuEntry[][] => {
  const menu: MenuEntry[][] = [
    [
      {
        label: i18next.t('action.detail', { ns: 'common' }),
        type: 'nav',
        link: `/users/${user.email}/detail`
      }
    ],
    [
      {
        disabled: user.deletedAt !== undefined,
        label: i18next.t('action.deactivate', { ns: 'common' }),
        onClick: () => {
          deactivateCallback(user);
        },
        type: 'button'
      },
      {
        disabled: user.deletedAt !== undefined,
        label: i18next.t('userForm.resetPassword.action', { ns: 'user' }),
        onClick: () => {
          resetPasswordCallback(user);
        },
        type: 'button'
      }
    ]
  ];

  if (user?.role && isDentistRole(user.role)) {
    menu[0].push(
      {
        label: i18next.t('datagrid.tagsModalModal.action', { ns: 'user' }),
        onClick: () => {
          tagsCallback(user);
        },
        type: 'button'
      },
      {
        label: i18next.t('datagrid.setAuthorizationOrder.action', { ns: 'user' }),
        onClick: () => {
          setAuthorizationOrderCallback(user);
        },
        type: 'button',
        disabled: user.isAllowedToOrder || user.deletedAt !== undefined
      }
    );
  }
  return menu;
};

export const getColumnSettings = (
  tagsCallback: (user: UserInfo) => void,
  deactivateCallback: (user: UserInfo) => void,
  resetPasswordCallback: (user: UserInfo) => void,
  setAuthorizationOrderCallback: (user: UserInfo) => void
): TypeColWithNamePropertyPlatform[] => [
  colDeletedAt,
  {
    ...getColumnOptions('email', i18next.t('datagrid.columns.email', { ns: 'user' }), 1),
    render: ({ data }) => {
      return <Link href={`/users/${data.email}/detail`} label={`${data.email}`}></Link>;
    }
  },
  getColumnOptions('firstName', i18next.t('datagrid.columns.firstName', { ns: 'user' })),
  getColumnOptions('lastName', i18next.t('datagrid.columns.lastName', { ns: 'user' })),
  {
    ...getColumnOptions('role', i18next.t('datagrid.columns.role', { ns: 'user' }), 1),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: DentistRole.DENTIST, label: i18next.t('roles.DENTIST', { ns: 'user' }) },
        { id: DentistRole.ASSISTANT, label: i18next.t('roles.ASSISTANT', { ns: 'user' }) },
        {
          id: LabUserRole.LAB_DESIGNER,
          label: i18next.t('roles.LAB_DESIGNER', { ns: 'user' })
        },
        {
          id: LabUserRole.PROSTHETIST,
          label: i18next.t('roles.PROSTHETIST', { ns: 'user' })
        },
        {
          id: LabUserRole.SUPERVISOR,
          label: i18next.t('roles.SUPERVISOR', { ns: 'user' })
        },
        {
          id: LabUserRole.TECHNICIAN,
          label: i18next.t('roles.TECHNICIAN', { ns: 'user' })
        },
        { id: DesignerRole.DESIGNER, label: i18next.t('roles.DESIGNER', { ns: 'user' }) },
        { id: LabUserRole.SUPPORT, label: i18next.t('roles.SUPPORT', { ns: 'user' }) },
        {
          id: AdminRole.ADMIN_PARTNER,
          label: i18next.t('roles.ADMIN_PARTNER_SHORT', { ns: 'user' })
        },
        { id: AdminRole.ADMIN, label: i18next.t('roles.ADMIN', { ns: 'user' }) }
      ]
    },
    render: ({ data }) => {
      const roleInEnum =
        Object.keys(DentistRole).includes(data.role) ||
        Object.keys(AdminRole).includes(data.role) ||
        Object.keys(LabUserRole).includes(data.role) ||
        Object.keys(DesignerRole).includes(data.role)
          ? i18next.t(`roles.${data.role.toUpperCase()}`, {
              ns: 'user'
            })
          : null;

      return <p>{roleInEnum || '-'}</p>;
    }
  },
  {
    ...getColumnOptions(
      'lastConnection',
      i18next.t('datagrid.columns.lastConnection', { ns: 'user' }),
      1,
      false
    ),
    render: ({ value }) => {
      return value ? moment(value).format(i18next.t('date.middle', { ns: 'common' })) : '-';
    }
  },
  {
    ...getColumnOptions(
      EstablishmentPayload.CLINIC,
      i18next.t('datagrid.columns.clinic', { ns: 'user' }),
      1
    ),
    render: ({ value }) => {
      return value?.name;
    }
  },
  {
    ...getColumnOptions(
      EstablishmentPayload.LABORATORY,
      i18next.t('datagrid.columns.laboratory', { ns: 'user' }),
      1
    ),
    render: ({ value }) => {
      return value?.name;
    }
  },
  {
    ...getColumnOptions(
      EstablishmentPayload.DESIGN_CENTER,
      i18next.t('datagrid.columns.designCenter', { ns: 'user' }),
      1
    ),
    render: ({ value }) => {
      return value?.name;
    }
  },
  {
    ...getColumnOptions('country', i18next.t('datagrid.columns.country', { ns: 'user' }), 1, false),
    render: ({ data }) => {
      let country = '-';

      if (data.clinic) {
        country = i18next.t(`countries.${data.clinic.address.country.toLowerCase()}`, {
          ns: 'common'
        });
      } else if (data.laboratory) {
        country = i18next.t(`countries.${data.laboratory.address.country.toLowerCase()}`, {
          ns: 'common'
        });
      }

      return <Text label={country}></Text>;
    }
  },
  {
    ...getColumnOptions('menu', '', 1, false),
    maxWidth: 50,
    showColumnMenuTool: false,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton faIconClass="ellipsis-vertical" isActive={active} radius="full" />
          )}
          data={buildMenu(
            data,
            tagsCallback,
            deactivateCallback,
            resetPasswordCallback,
            setAuthorizationOrderCallback
          )}
        />
      );
    }
  }
];

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value)
    .forEach((filter) => {
      switch (filter.name) {
        case EstablishmentPayload.CLINIC:
        case EstablishmentPayload.LABORATORY:
        case EstablishmentPayload.DESIGN_CENTER:
          filters = `${filters}&filter.${filter.name}Name=$ilike:${filter.value}`;
          break;
        case 'role': {
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.${filter.name}=$in:${multiSelectValues.join(',')}`;
          break;
        }
        case 'deletedAt':
          filters = `${filters}&filter.deletedAt=${
            filter.value === 'active' ? '$null' : '$not:$null'
          }`;
          break;
        default:
          filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
          break;
      }
    });
  return filters;
};

export const getSort = (sortInfo: SortReducer | SortReducer[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      if (
        sort.name === EstablishmentPayload.CLINIC ||
        sort.name === EstablishmentPayload.LABORATORY ||
        sort.name === EstablishmentPayload.DESIGN_CENTER
      ) {
        urlSort += `${urlSort}&sortBy=${sort.name}Name:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      } else {
        urlSort += `${urlSort}&sortBy=${sort.name}:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      }
    });
  }
  return urlSort;
};

export const mapUsers = (users: UserInfo[]): UserInfo[] => {
  return users?.map((user: UserInfo) => mapUser(user));
};

export const mapUser = (user: UserInfo): UserInfo => {
  if (user?.firstName && user?.lastName) {
    return {
      ...user,
      firstName: capitalize(user.firstName),
      lastName: capitalize(user.lastName)
    };
  }
  return user;
};

export const rowClassName = ({ data }: { data: UserInfo }): string | undefined => {
  if (data.deletedAt) {
    return stylesDataGrid['datagrid-feature__row--disabled'];
  }
  return undefined;
};
