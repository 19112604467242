import { AdminRole } from '../../enum/user.ts';
import {
  CatalogManagementPermissions,
  OrderDetailPagePermissions,
  OrderManagementPermissions,
  PrivateAppPermissions,
  UserManagementPermissions
} from '../../models/connected-user.tsx';
import { ReducerState } from './auth.selectors.tsx';
import { createSelector } from '@reduxjs/toolkit';

const selectConnectedUser = (state: ReducerState) => state.authenticationState.userInfo;

const getPrivateAppPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): PrivateAppPermissions => {
    return {
      canAccessDashboard: connectedUser?.role !== AdminRole.ADMIN_PARTNER,
      canAccessOrders: !!connectedUser,
      canAccessOrderDetail: connectedUser?.role !== AdminRole.ADMIN_PARTNER,
      canAccessUsers: !!connectedUser,
      canAccessCatalog: connectedUser?.role !== AdminRole.ADMIN_PARTNER
    };
  }
);

const getOrderDetailsPagePermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): OrderDetailPagePermissions => {
    return {
      canViewDetail: !!connectedUser,
      canViewComments: !!connectedUser,
      canViewHistory: !!connectedUser,
      canViewDentistInfo: !!connectedUser
    };
  }
);

const getUserManagementPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): UserManagementPermissions => {
    return {
      canCreateDentist: !!connectedUser,
      canCreateAssistant: connectedUser?.role !== AdminRole.ADMIN_PARTNER,
      canCreateLabUserRole: connectedUser?.role !== AdminRole.ADMIN_PARTNER,
      canCreateAdminRole: connectedUser?.role === AdminRole.ADMIN,
      canEditEstablishment: connectedUser?.role === AdminRole.ADMIN,
      canViewPartners: connectedUser?.role === AdminRole.ADMIN,
      canCreatePartners: connectedUser?.role === AdminRole.ADMIN,
      canEditPartners: connectedUser?.role === AdminRole.ADMIN
    };
  }
);

const getCatalogManagementPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): CatalogManagementPermissions => {
    return {
      canAdministrateCatalog: connectedUser?.role === AdminRole.ADMIN
    };
  }
);

const getOrderManagementPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): OrderManagementPermissions => {
    return {
      canExportOrders: connectedUser?.role === AdminRole.ADMIN
    };
  }
);

export {
  getPrivateAppPermissionsSelector,
  getOrderDetailsPagePermissionsSelector,
  getUserManagementPermissionsSelector,
  getCatalogManagementPermissionsSelector,
  getOrderManagementPermissionsSelector
};
