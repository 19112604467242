import { useEffect, useState } from 'react';
import styles from './activate-product-form-for-lab.module.scss';
import {
  AttentionBox,
  Box,
  Button,
  Dropdown,
  Skeleton,
  TextField
} from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getMessageError, mapForDropdown } from '../../../../../utils/utils';
import { Product, ProductLabActivation } from '../../../../../models/product';
import useForm from '../../../../../utils/useForm';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { StringObject, UnknownObject } from '../../../../../models/common';
import { DropdownItem } from '../../../../../models/form';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { useGetAllLaboratoriesQuery } from '../../../../../services/establishments.services';
import {
  useActivateLabProductMutation,
  useLazyGetLabProductsQuery
} from '../../../../../services/products-rtkq.services.tsx';

type props = {
  onSubmitCallback: (labName: string) => void;
  product: Product;
};

const ActivateProductForLabForm = ({ onSubmitCallback, product }: props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['catalog']);
  const {
    data: laboratories,
    isLoading: isLaboratoriesLoading,
    isSuccess: isSuccessGetAllLaboratories
  } = useGetAllLaboratoriesQuery();
  const [createLabProduct] = useActivateLabProductMutation();
  const [getLabProducts] = useLazyGetLabProductsQuery();
  const [laboratoryListForDropdown, setLaboratoryListForDropdown] = useState<DropdownItem[]>([]);

  const [labsActivatedToProduct, setLabsActivatedToProduct] = useState<ProductLabActivation[]>();
  const [isLabActivationsLoading, setIsLabActivationsLoading] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLabActivationsLoading(true);
    if (product?.id) {
      getLabProducts(product.id)
        .then(({ data }) => {
          setLabsActivatedToProduct(data);
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ColorPropsEnum.DANGER
            })
          )
        )
        .finally(() => setIsLabActivationsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (isSuccessGetAllLaboratories) {
      setLaboratoryListForDropdown(mapForDropdown(laboratories.data));
    }
  }, [isSuccessGetAllLaboratories]);

  const isActivatedLab = (labId: number): boolean =>
    labsActivatedToProduct?.some((activatedLab) => activatedLab.labId === labId) ?? false;

  const getLabs = (): DropdownItem[][] => {
    const labs: DropdownItem[] = laboratoryListForDropdown?.map((lab) => {
      const isActivated = isActivatedLab(lab.value);
      return {
        ...lab,
        label:
          lab.label +
          (isActivated ? t('products.datagrid.activateLab.form.laboratory.activated') : ''),
        isDisabled: isActivated
      };
    });
    const canBeActivated = labs.filter((lab) => !lab.isDisabled);
    const activated = labs.filter((lab) => lab.isDisabled);
    const result: DropdownItem[][] = [];
    if (canBeActivated?.length) {
      result.push(canBeActivated);
    }
    if (activated?.length) {
      result.push(activated);
    }
    return result;
  };
  const onSubmit = () => {
    setIsLoading(true);
    if (product?.id) {
      const labActivation = mapProductLabActivation();
      createLabProduct({ productId: product.id, lab: labActivation })
        .then(() => {
          onSubmitCallback(labActivation.labName);
        })
        .catch((err) => {
          setError(getMessageError(err));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const mapProductLabActivation = (): ProductLabActivation => {
    const labId: number = values?.labId as number;
    const labName = laboratoryListForDropdown?.find((lab) => lab.value === labId)?.label;
    return {
      labId: labId,
      labName: labName as string,
      productionTime: values?.productionTime ? +values.productionTime : 0,
      unitPrice: values?.unitPrice ? +values.unitPrice : 0
    };
  };

  const validateCallback = (values: UnknownObject) => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (
        !values[key] ||
        (['productionTime', 'unitPrice'].includes(key) && parseInt(values[key] as string) <= 0) ||
        (key === 'productionTime' && +values[key] !== parseInt(values[key] as string))
      ) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      labId: undefined,
      productionTime: '',
      unitPrice: ''
    },
    onSubmit,
    validateCallback
  );

  return (
    <>
      {error && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={error}
          className={styles['activation-form__attention-box']}
          onClose={() => setError(null)}
        />
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['activation-form__form']}>
        <form onSubmit={handleSubmit}>
          {isLabActivationsLoading || isLaboratoriesLoading ? (
            <>
              <Skeleton type="text" className={styles['activation-form__form__skeleton']} />
              <Skeleton type="text" className={styles['activation-form__form__skeleton']} />
              <Skeleton type="text" className={styles['activation-form__form__skeleton']} />
            </>
          ) : (
            <>
              <Dropdown
                data={getLabs()}
                value={values?.labId}
                label={t('products.datagrid.activateLab.form.laboratory.label')}
                placeholder={t('userForm.choose', { ns: 'user' })}
                onChange={(newValue: number) => handleSelect(newValue, 'labId')}
                helperText={
                  errors['labId'] && t('products.datagrid.activateLab.form.laboratory.error')
                }
                variant={errors['labId'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['activation-form__form__input']}
              />
              <TextField
                id={'productionTime'}
                name={'productionTime'}
                value={values?.productionTime}
                label={t('products.datagrid.activateLab.form.productionTime.label')}
                helperText={
                  errors['productionTime']
                    ? t('products.datagrid.activateLab.form.productionTime.error')
                    : t('products.datagrid.activateLab.form.productionTime.helper')
                }
                variant={errors['productionTime'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                placeholder={'0'}
                type={'number'}
                className={styles['activation-form__form__input']}
                onBlur={handleBlur}
                onChange={handleChange}></TextField>
              <TextField
                id={'unitPrice'}
                name={'unitPrice'}
                value={values?.unitPrice}
                label={t('products.datagrid.activateLab.form.unitPrice.label')}
                helperText={
                  errors['productionTime']
                    ? t('products.datagrid.activateLab.form.unitPrice.error')
                    : t('products.datagrid.activateLab.form.unitPrice.helper')
                }
                variant={errors['unitPrice'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                placeholder={'0'}
                type={'number'}
                className={styles['activation-form__form__input']}
                onBlur={handleBlur}
                onChange={handleChange}></TextField>
            </>
          )}
          <div className="form__submit-button">
            <Button
              label={t('products.datagrid.activateLab.form.action')}
              isLoading={isLoading}
              type="submit"
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export default ActivateProductForLabForm;
