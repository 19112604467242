export enum ErrorUIType {
  ATTENTION_BOX = 'ATTENTION_BOX',
  TOAST = 'TOAST',
  FIELD = 'FIELD'
}

export enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info'
}

export enum ErrorCode {
  auth_wrong_password = 'auth/wrong-password',
  auth_user_not_found = 'auth/user-not-found',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  auth_invalid_email = 'auth/user-not-found',
  auth_user_token_expired = 'auth/user-token-expired',
  auth_too_many_requests = 'auth/too-many-requests',
  auth_missing_password = 'auth/missing-password',
  USR_ESTABLISHMENT_001 = 'USR-ESTABLISHMENT-001',
  USR_ESTABLISHMENT_002 = 'USR-ESTABLISHMENT-002',
  USR_ESTABLISHMENT_003 = 'USR-ESTABLISHMENT-003',
  USR_ACCESS_001 = 'USR-ACCESS-001',
  USR_USERS_004 = 'USR-USERS-004',
  USR_USERS_005 = 'USR-USERS-005',
  USR_FIREBASE_001 = 'USR-FIREBASE-001',
  USR_FIREBASE_002 = 'USR-FIREBASE-002',
  USR_FIREBASE_003 = 'USR-FIREBASE-003',
  USR_FIREBASE_004 = 'USR-FIREBASE-004',
  USR_FIREBASE_005 = 'USR-FIREBASE-005',
  USR_FIREBASE_006 = 'USR-FIREBASE-006',
  ORDERS_PRD_002 = 'ORDERS-PRD-002',
  ORDERS_PAYLOAD_VALIDATION_001 = 'ORDERS-PAYLOAD-VALIDATION-001',
  ORDERS_PAYLOAD_VALIDATION_002 = 'ORDERS-PAYLOAD-VALIDATION-002',
  ORDERS_PAYLOAD_VALIDATION_003 = 'ORDERS-PAYLOAD-VALIDATION-003',
  MANU_PAYLOAD_VALIDATION_001 = 'MANU-PAYLOAD-VALIDATION-001',
  MANU_PAYLOAD_VALIDATION_002 = 'MANU-PAYLOAD-VALIDATION-002',
  USR_PAYLOAD_VALIDATION_001 = 'USR-PAYLOAD-VALIDATION-001',
  USR_PAYLOAD_VALIDATION_002 = 'USR-PAYLOAD-VALIDATION-002',
  ORDERS_LAB_PRODUCT_001 = 'ORDERS-LAB-PRODUCT-001',
  ORDERS_LAB_PRODUCT_002 = 'ORDERS-LAB-PRODUCT-002',
  ORDERS_LAB_PRODUCT_003 = 'ORDERS-LAB-PRODUCT-003',
  ORDERS_LAB_PRODUCT_004 = 'ORDERS-LAB-PRODUCT-004',
  ORDERS_PRD_001 = 'ORDERS-PRD-001',
  ORDERS_COMPONENTS_001 = 'ORDERS-COMPONENTS-001',
  ORDERS_AUTH_001 = 'ORDERS-AUTH-001',
  MANU_AUTH_001 = 'MANU-AUTH-001',
  MANU_ORDER_003 = 'MANU-ORDER-003',
  PRODUCTION_FILES_ZIP_ERROR = 'PRODUCTION-FILES-ZIP-ERROR'
}
