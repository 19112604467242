import { EstablishmentsReducer } from '../../models/establishment';
import { createSelector } from '@reduxjs/toolkit';

interface ReducerState {
  establishmentsState: EstablishmentsReducer;
}

export const clinicListSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) =>
    establishments?.clinicList ? establishments.clinicList : []
);

export const laboratoryListSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) =>
    establishments?.laboratoryList ? establishments.laboratoryList : []
);

export const designCenterListSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) =>
    establishments?.designCenterList ? establishments.designCenterList : []
);
