import { Box, Button, NumberInput, RadioList } from '@platform-storybook/circlestorybook';
import i18next from 'i18next';
import { DentalNotation, DentistRole, Language } from '../../../../../../enum/user.ts';
import { UserInfo } from '../../../../../../models/user.tsx';
import styles from './user-forms.module.scss';
import { dentalNotations, isDentistRole, languages } from '../../../../../../utils/utils.tsx';
import useForm from '../../../../../../utils/useForm.tsx';
import { ColorPropsEnum } from '../../../../../../enum/color.enum.ts';
import { useGetUserQuery } from '../../../../../../services/users-rtkq.services.tsx';
import { StringObject } from '../../../../../../models/common.tsx';

type Props = {
  email: string;
  saveCallback: (user: Partial<UserInfo>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const CustomizationForm = ({ email, saveCallback, closeSideNavCallback }: Props) => {
  // this will not refetch, it just reselects
  const { data: user } = useGetUserQuery(email);
  const isDentistOrAssistantRole = isDentistRole(user?.role as DentistRole);
  const deliveryTimeMandatory = i18next.t('userForm.deliveryTimeMandatory', { ns: 'user' });

  const saveCustomizationInfo = () => {
    if (user?.email) {
      const deliveryTime = isDentistOrAssistantRole ? +(values.deliveryTime as number) : undefined;
      saveCallback(
        {
          ...values,
          deliveryTime
        },
        user?.email
      );
    }
    closeSideNavCallback(false);
  };

  const onSubmit = () => {
    saveCustomizationInfo();
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (
        (!values[key] && key !== 'deliveryTime') ||
        (key === 'deliveryTime' && isDentistOrAssistantRole && values[key] === undefined)
      ) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleSelect, handleChange, handleBlur } = useForm(
    {
      dentalNotation: user?.dentalNotation ?? DentalNotation.ISO,
      language: user?.language ?? Language.fr_FR,
      deliveryTime: user?.deliveryTime || user?.deliveryTime === 0 ? +user.deliveryTime : undefined
    },
    onSubmit,
    validateAllFieldsRequired
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        <RadioList
          title={i18next.t('userForm.dentalNotation', { ns: 'user' })}
          options={dentalNotations}
          name="dentalNotation"
          selectedValue={values.dentalNotation}
          onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
          className={styles['user-forms__input']}
          data-cy="user-forms-dental-notation"
        />
        <RadioList
          title={i18next.t('userForm.language', { ns: 'user' })}
          options={languages}
          name="language"
          selectedValue={values.language}
          onClick={(newValue: Language) => handleSelect(newValue, 'language')}
          className={styles['user-forms__input']}
          data-cy="user-forms-language"
        />
        {isDentistOrAssistantRole && (
          <NumberInput
            label={i18next.t('userForm.deliveryTime', { ns: 'user' })}
            className={styles['create-user-form__form__input']}
            id="deliveryTime"
            name="deliveryTime"
            value={values.deliveryTime}
            unitLabel={i18next.t('userForm.deliveryUnitLabel', { ns: 'user' })}
            min={0}
            helperText={errors.deliveryTime ? deliveryTimeMandatory : undefined}
            variant={errors.deliveryTime ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            radius="small"
            onBlur={handleBlur}
            onChange={handleChange}
            data-cy="user-forms-delivery-delay"
          />
        )}
        <div className="form__submit-button">
          <Button
            label={i18next.t('action.update', { ns: 'common' })}
            data-cy="user-forms-customization-submit"
            type="submit"
          />
        </div>
      </form>
    </Box>
  );
};
export default CustomizationForm;
