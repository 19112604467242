import { api } from './api';
import { Establishment, EstablishmentsResponse } from '../models/establishment';
import { EstablishmentType } from '../enum/establishment';

export const establishmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneEstablishment: builder.query<Establishment, number>({
      query: (id: number) => ({
        url: `/establishments/${id}`,
        method: 'GET'
      }),
      providesTags: ['OneEstablishment']
    }),
    getAllClinics: builder.query<EstablishmentsResponse, void>({
      query: () => `/establishments?limit=-1&filter.type=${EstablishmentType.CLINIC}`,
      providesTags: ['Clinics']
    }),
    getAllLaboratories: builder.query<EstablishmentsResponse, void>({
      query: () => `/establishments?limit=-1&filter.type=${EstablishmentType.LABORATORY}`,
      providesTags: ['Laboratories']
    }),
    getAllLaboratoriesPagination: builder.query({
      query: (arg: { page: number; limit: number; filters: string; sort: string }) => {
        const { page, limit, filters, sort } = arg;
        return `/establishments?page=${page}&limit=${limit}&filter.type=${EstablishmentType.LABORATORY}${filters}${sort}`;
      },
      providesTags: ['Laboratories']
    }),
    getAllDesignCenters: builder.query<EstablishmentsResponse, void>({
      query: () => `/establishments?limit=-1&filter.type=${EstablishmentType.DESIGN_CENTER}`,
      providesTags: ['DesignCenters']
    }),
    createEstablishment: builder.mutation<Establishment, Establishment>({
      query: (payload: Establishment) => ({
        url: '/establishments',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Laboratories']
    }),
    updateEstablishment: builder.mutation<Establishment, Establishment>({
      query: ({ id, ...patch }: Establishment) => ({
        url: `/establishments/${id}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['Laboratories', 'OneEstablishment']
    }),
    deactivateEstablishment: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/establishments/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OneEstablishment']
    })
  })
});

export const {
  useGetOneEstablishmentQuery,
  useGetAllClinicsQuery,
  useGetAllLaboratoriesQuery,
  useLazyGetAllLaboratoriesPaginationQuery,
  useGetAllDesignCentersQuery,
  useCreateEstablishmentMutation,
  useUpdateEstablishmentMutation,
  useDeactivateEstablishmentMutation
} = establishmentsApi;
