import { WorkflowManufacturingStepEnum, WorkflowStepEnum } from '../enum/workflow-step';
import { CircleCadVersion } from './circle-cad-version';
import { Angulation, Aspect, BrandReference, Material, Shade, Shape } from './common-types';
import { Comment } from './order';
import { ToothStructureEnum } from '../enum/component.ts';
import { ProductSubCategoryEnum } from '../enum/product.subcategory.enum.ts';
import { ComponentType } from '../enum/component-type.ts';

export interface ManufacturingOrder {
  id: number;
  orderNumber: string;
  assigneeName?: string | null;
  assigneeEmail?: string | null;
  controlled?: boolean;
  tags: string[];
  dentistEmail: string;
  dentistName: string;
  patientId?: number;
  instructions?: string;
  creationDate: Date;
  expectedDate: Date;
  limitShippingDate: Date;
  circleCadVersion?: CircleCadVersion;
  toManufacture?: boolean;
  isUrgent?: boolean;
  manufacturingDate?: Date;
  products: ManufacturingOrderProduct[];
  currentStep: WorkflowStepEnum;
  shipping?: Shipping;
  files: string[];
  deletedAt?: string;
  streamingParameters?: string;
  labId: number;
  labName: string;
  families: string[];
  comments?: Comment[];
  isInError: boolean;
}

export interface ManufacturingOrderProduct {
  id: number;
  labelFr: string;
  labelEn: string;
  family: string;
  class?: string;
  category?: string;
  subCategory?: ProductSubCategoryEnum;
  stumpPositions?: number[];
  injectionPositions?: number[];
  teethPositions?: number[];
  components: ManufacturingOrderComponent[];
  isCompliantCircleCad?: boolean;
  isOneDesign?: boolean;
  isChairside?: boolean;
  deletedAt?: string;
}

export interface ManufacturingOrderPaginatedResponse {
  data: ManufacturingOrder[];
  links: object;
  meta: {
    currentPage: number;
    filter: object;
    itemsPerPage: number;
    sortBy: Array<Array<string>>;
    totalItems: number;
    totalPages: number;
  };
}
export interface ManufacturingOrderComponentStructure {
  structure: ToothStructureEnum;
  positions: number[];
}

export interface ManufacturingOrderComponent {
  id: number;
  componentType: ComponentType;
  material?: Material;
  shade?: Shade;
  brandReference?: BrandReference;
  shape?: Shape;
  structures?: ManufacturingOrderComponentStructure[];
  angulation?: Angulation;
  aspect?: Aspect;
  orderNumber?: string;
  toManufacture?: boolean;
  isUrgent?: boolean;
  productId?: number;
  family?: string;
  manufacturingStep: WorkflowManufacturingStepEnum;
  commentExists: boolean;
  isInError: boolean;
}

export interface Shipping {
  id: number;
  shippingDate?: Date;
  estimatedDeliveryDate?: Date;
  deliveryLink?: string;
  address: string;
  additionalAddress?: string;
  zipCode: string;
  city: string;
  country: string;
  region?: string;
}

export interface ShippingCreation {
  shippingDate?: Date;
  estimatedDeliveryDate?: Date;
  deliveryLink?: string;
  address: string;
  additionalAddress?: string;
  zipCode: string;
  city: string;
  country: string;
  region?: string;
}

export interface PostShipping {
  orderNumber?: string;
  shipping: ShippingCreation;
}

export interface DesignRequest {
  orderNumber: string;
  circleCloudPool?: string;
  circleCloudRegion?: string;
  language?: string;
}

export interface QualityControlFileUrl {
  downloadLink: string;
}

export interface ManufacturingOrderFiles {
  orderNumber: string;
  uploadedManufacturingFiles: ManufacturingOrderFile[];
  productFiles: ProductFiles[];
}

export interface ProductFiles {
  id: number;
  uploadedManufacturingFiles: ManufacturingOrderFile[];
  componentFiles: ComponentFiles[];
}

export interface ComponentFiles {
  id: number;
  uploadedManufacturingFiles: ManufacturingOrderFile[];
}

export interface ManufacturingOrderFile {
  type: ManufacturingFileTypeEnum;
  tag: string;
  fileName: string;
  extension: string;
  mimeType: string;
  downloadLink: string;
}

export enum ManufacturingFileTypeEnum {
  MESH = 'MESH',
  MESH_METADATA = 'MESH_METADATA',
  MARGIN_LINE = 'MARGIN_LINE',
  DESIGN_TRACABILITY_INFO = 'DESIGN_TRACABILITY_INFO',
  JSON_CAM_FILE = 'JSON_CAM_FILE'
}

export interface ProductionFileUrl {
  downloadUrl: string;
}
