import { api } from './api';
import { DentistInfos, DentistListResponse, LabUser, UserInfo } from '../models/user';
import { mapUser } from '../pages/private/main-users-page/users-page/users.tsx';
import { DentistRole, LabUserRole } from '../enum/user.ts';
import { capitalizeFirstLetter } from '../utils/utils.tsx';

interface DentistsFormattedList {
  data: DentistInfos[];
  count: number;
}

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation<UserInfo, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      })
    }),
    createUser: builder.mutation<UserInfo, UserInfo>({
      query: (user) => ({
        url: '/users',
        method: 'POST',
        body: user
      })
    }),
    getAllDentists: builder.query<DentistsFormattedList, void>({
      query: () => {
        return `/users?page=1&limit=-1&filter.role=$in:${DentistRole.DENTIST},${DentistRole.ASSISTANT}&filter.deletedAt=$null`;
      },
      transformResponse: (response: DentistListResponse) => {
        return {
          data: response.data?.map((user: DentistInfos) => {
            return {
              ...user,
              firstName: capitalizeFirstLetter(user.firstName),
              lastName: capitalizeFirstLetter(user.lastName)
            };
          }),
          count: response.meta.totalItems
        };
      }
    }),
    getLabUsers: builder.query<LabUser[], void>({
      query: () =>
        `/users?limit=-1&filter.role=$in:${LabUserRole.LAB_DESIGNER},${LabUserRole.SUPERVISOR},${LabUserRole.PROSTHETIST},${LabUserRole.TECHNICIAN}&filter.deletedAt=$null`,
      // @ts-expect-error avoid risk of regression
      transformResponse: (response: { data: LabUser[] }) => {
        return response.data.map((user: LabUser) => {
          return {
            ...user,
            firstName: capitalizeFirstLetter(user.firstName),
            lastName: capitalizeFirstLetter(user.lastName)
          };
        });
      }
    }),
    patchUser: builder.mutation<UserInfo, Partial<UserInfo>>({
      query: ({ email, ...patch }: { email: string; patch: Partial<UserInfo> }) => ({
        url: `/users/${email}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['OneUser']
    }),
    getUser: builder.query<UserInfo, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'GET'
      }),
      providesTags: ['OneUser']
    }),
    deactivateUser: builder.mutation<void, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OneUser']
    }),
    resetPasswordUser: builder.mutation<void, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      }),
      invalidatesTags: ['OneUser']
    }),
    getConnectedUser: builder.query<UserInfo, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET'
      }),
      transformResponse: (response: UserInfo) => {
        return mapUser(response);
      },
      providesTags: ['ConnectedUser']
    })
  })
});

export const {
  useResetPasswordMutation,
  useCreateUserMutation,
  useGetAllDentistsQuery,
  useGetLabUsersQuery,
  useGetConnectedUserQuery,
  usePatchUserMutation,
  useLazyGetConnectedUserQuery,
  useGetUserQuery,
  useDeactivateUserMutation,
  useResetPasswordUserMutation
} = usersApi;
