import {
  FilterProps,
  MenuEntry,
  SortDirection,
  SortReducer,
  TypeColWithNamePropertyPlatform
} from '../../../../models/datagrid.tsx';
import i18next from 'i18next';
import { DropdownMenu, IconButton } from '@platform-storybook/circlestorybook';
import { getColumnOptions } from '../../../../features/datagrid/datagrid.utils.ts';
import { Partner } from '../../../../models/partner.tsx';
import { UserManagementPermissions } from '../../../../models/connected-user.tsx';

const buildMenu = (
  partner: Partner,
  editCallBack: (partner: Partner) => void,
  connectedUserPermissions: UserManagementPermissions
): MenuEntry[][] => {
  const menu: MenuEntry[][] = [
    [
      {
        disabled: !connectedUserPermissions.canEditPartners,
        label: i18next.t('action.edit', { ns: 'common' }),
        onClick: () => {
          editCallBack(partner);
        },
        type: 'button'
      }
    ]
  ];
  return menu;
};

export const getColumnSettings = (
  editCallBack: (partner: Partner) => void,
  connectedUserPermissions: UserManagementPermissions
): TypeColWithNamePropertyPlatform[] => [
  {
    ...getColumnOptions('code', i18next.t('partnerModal.code', { ns: 'user' }), 1)
  },
  {
    ...getColumnOptions('callbackUrl', i18next.t('partnerModal.callbackUrl', { ns: 'user' }), 1)
  },
  {
    ...getColumnOptions('menu', '', 1, false),
    maxWidth: 50,
    showColumnMenuTool: false,
    render: ({ data }) => {
      return (
        <DropdownMenu
          data-cy="kebab-menu"
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton faIconClass="ellipsis-vertical" isActive={active} radius="full" />
          )}
          data={buildMenu(data, editCallBack, connectedUserPermissions)}
        />
      );
    }
  }
];

export const getSort = (sortInfo: SortReducer | SortReducer[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      urlSort += `${urlSort}&sortBy=${sort.name}:${
        sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
      }`;
    });
  }
  return urlSort;
};

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value)
    .forEach((filter) => {
      filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
    });
  return filters;
};
