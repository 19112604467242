import { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, SideBarModal } from '@platform-storybook/circlestorybook';
import { FilterReducer, LoadDataProps } from '../../../../models/datagrid.tsx';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.tsx';
import { usersActions } from '../../../../store/users/users.reducers.tsx';
import styles from './users-page.module.scss';
import { useNavigate } from 'react-router-dom';
import DatagridFeature from '../../../../features/datagrid/DatagridFeature.tsx';
import { getUsers } from '../../../../services/users.services.tsx';
import { getColumnSettings, getFilters, getSort, mapUsers, rowClassName } from './users.tsx';
import { UserInfo } from '../../../../models/user.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { getMessageError } from '../../../../utils/utils.tsx';
import { useTranslation } from 'react-i18next';
import TagsForm from './tags-form/TagsForm.tsx';
import { usersDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors.tsx';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers.tsx';
import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { ToastType } from '../../../../enum/feedback.ts';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import {
  useDeactivateUserMutation,
  usePatchUserMutation,
  useResetPasswordMutation
} from '../../../../services/users-rtkq.services.tsx';

const UsersPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'user']);

  const [patchUser, { isLoading: isLoadingPatchUser }] = usePatchUserMutation();
  const [resetPasswordUser] = useResetPasswordMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const [isDialogDeactivateOpened, setIsDialogDeactivateOpened] = useState<boolean>(false);
  const [isDialogResetPasswordOpened, setIsDialogResetPasswordOpened] = useState<boolean>(false);
  const [isDialogSetAuthorizationOrderOpened, setIsDialogSetAuthorizationOrderOpened] =
    useState<boolean>(false);
  const [user, setUser] = useState<UserInfo>();
  const datagridSettings = useAppSelector(usersDatagridStateSelector);
  const [isLoadingDeactivateUser, setIsLoadingDeactivateUser] = useState<boolean>(false);
  const [isLoadingAllowUserToOrder, setIsLoadingAllowUserToOrder] = useState<boolean>(false);

  const [sideBarOpened, setSideBarOpened] = useState(false);

  const loadData = ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);

    return getUsers(page, limit, filters, sort)
      .then((users) => ({
        data: mapUsers(users.data.data),
        count: users.data.meta.totalItems
      }))
      .catch(() => ({ data: [], count: 0 }));
  };

  const dataSource = useCallback(loadData, [
    isLoadingDeactivateUser,
    isLoadingAllowUserToOrder,
    isLoadingPatchUser
  ]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'users',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'users',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };

  const handleEscKey = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsDialogDeactivateOpened(false);
      setIsDialogResetPasswordOpened(false);
      setSideBarOpened(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
  };

  const tagsCallback = async (user: UserInfo) => {
    setUser(user);
    setSideBarOpened(true);
  };

  const deactivateCallback = async (user: UserInfo) => {
    setUser(user);
    setIsDialogDeactivateOpened(true);
  };

  const deactivate = async () => {
    setIsDialogDeactivateOpened(false);
    setIsLoadingDeactivateUser(true);
    if (user) {
      deactivateUser(user.email as string)
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('datagrid.deactivate.deactivated', { ns: 'user' }),
              type: ToastType.SUCCESS
            })
          );
          setUser(undefined);
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        )
        .finally(() => {
          setIsLoadingDeactivateUser(false);
        });
    }
  };

  const setAuthorizationOrderCallback = (user: UserInfo) => {
    setUser(user);
    setIsDialogSetAuthorizationOrderOpened(true);
  };

  const allowUserToOrder = () => {
    setIsDialogSetAuthorizationOrderOpened(false);
    setIsLoadingAllowUserToOrder(true);
    if (user) {
      patchUser({ isAllowedToOrder: true, email: user.email })
        .unwrap()
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('datagrid.setAuthorizationOrder.success', { ns: 'user' }),
              type: ToastType.SUCCESS
            })
          );
          setUser(undefined);
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        )
        .finally(() => {
          setIsLoadingAllowUserToOrder(true);
        });
    }
  };

  const resetPasswordCallback = async (user: UserInfo) => {
    setUser(user);
    setIsDialogResetPasswordOpened(true);
  };

  const resetPassword = async () => {
    setIsDialogResetPasswordOpened(false);
    if (user) {
      resetPasswordUser(user?.email as string)
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('userForm.resetPassword.success', { ns: 'user' }),
              type: ToastType.SUCCESS
            })
          );
          setUser(undefined);
        })
        .catch((error) => {
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          );
        });
    }
  };

  const onSubmitTagsForm = () => {
    setSideBarOpened(false);
    dispatch(
      feedbackActions.setToast({
        message: t('datagrid.tagsModalModal.toast', { ns: 'user' }),
        type: ToastType.SUCCESS
      })
    );
  };

  return (
    <div>
      <Dialog
        title={t('datagrid.setAuthorizationOrder.dialog.title', {
          ns: 'user',
          user: `${user?.firstName} ${user?.lastName}`
        })}
        text={t('datagrid.setAuthorizationOrder.dialog.text', { ns: 'user' })}
        isOpened={isDialogSetAuthorizationOrderOpened}
        isLoading={isLoadingAllowUserToOrder}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('datagrid.setAuthorizationOrder.dialog.action', { ns: 'user' })}
        onCancel={() => setIsDialogSetAuthorizationOrderOpened(false)}
        onConfirm={allowUserToOrder}
      />
      <Dialog
        title={t('datagrid.deactivate.dialog.title', { ns: 'user', user: user?.email })}
        text={t('datagrid.deactivate.dialog.text', { ns: 'user' })}
        isOpened={isDialogDeactivateOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('action.deactivate', { ns: 'common' })}
        onCancel={() => setIsDialogDeactivateOpened(false)}
        isLoading={isLoadingDeactivateUser}
        onConfirm={deactivate}
      />
      <Dialog
        title={t('userForm.resetPassword.dialog.title', {
          ns: 'user',
          user: `${user?.firstName} ${user?.lastName}`
        })}
        text={t('userForm.resetPassword.dialog.text', { ns: 'user', email: user?.email })}
        isOpened={isDialogResetPasswordOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('userForm.resetPassword.dialog.action', { ns: 'user' })}
        onCancel={() => setIsDialogResetPasswordOpened(false)}
        onConfirm={resetPassword}
      />
      <div className={styles['users-page__actions']}>
        <Button
          category="text"
          label={t('datagrid.removeAllFilters')}
          onClick={() => {
            dispatch(datagridSettingsActions.resetUsersDatagridFilters());
          }}
        />
        <Button
          iconRight="plus"
          label={t('datagrid.actions.createUser', { ns: 'user' })}
          onClick={() => {
            dispatch(usersActions.setUser(undefined));
            navigate('/users/create');
          }}
        />
      </div>
      <DatagridFeature
        columns={getColumnSettings(
          tagsCallback,
          deactivateCallback,
          resetPasswordCallback,
          setAuthorizationOrderCallback
        )}
        style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
        filterValue={datagridSettings.filters}
        onFilterValueChange={onFilterValueChange}
        sortInfo={datagridSettings.sort}
        onSortInfoChange={onSortInfoChange}
        dataSource={dataSource}
        rowClassName={rowClassName}
      />

      {sideBarOpened && user && (
        <SideBarModal
          title={t('datagrid.tagsModalModal.title', {
            userName: `${user?.firstName} ${user?.lastName}`,
            ns: 'user'
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={() => handleClickCloseSidebar()}>
          <TagsForm onSubmitCallback={onSubmitTagsForm} user={user} />
        </SideBarModal>
      )}
    </div>
  );
};

export default UsersPage;
