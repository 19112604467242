import { useParams } from 'react-router-dom';
import UserDetails from '../../../../../features/user-details/UserDetails.tsx';

const UserDetailPage = () => {
  const { email } = useParams();

  return email && <UserDetails isReadOnly={true} email={email} />;
};

export default UserDetailPage;
