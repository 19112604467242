import { UsersReducer } from '../../models/user';

interface ReducerState {
  userState: UsersReducer;
}

export const userSelector = (state: ReducerState) => state.userState.user;

export const isLoadingCreationUserSelector = (state: ReducerState) =>
  state.userState.isLoadingCreationUser;
