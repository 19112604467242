import { Button, SideBarModal } from '@platform-storybook/circlestorybook';
import React, { forwardRef, useState } from 'react';
import { ModalOptions } from '../../pages/private/catalog-page/products-page/product-details-page/ProductDetailsPage';

export interface ActionBtn {
  label: string;
  icon: string;
  dataCy: string;
  modal?: ModalOptions;
  isDisabled?: boolean;
}

type Props = {
  title: string;
  actionBtn: ActionBtn;
  children: React.ReactElement;
  onCloseCallback?: () => void;
};

const AdminSidebarModal = forwardRef<HTMLButtonElement, Props>(
  ({ title, actionBtn, onCloseCallback, children }, ref) => {
    const [sideBarOpened, setSideBarOpened] = useState(false);

    const handleClickCloseSidebar = () => {
      setSideBarOpened(false);
      if (onCloseCallback) onCloseCallback();
    };

    const submitCallback = () => setSideBarOpened(false);
    const childrenWithCallback = React.cloneElement(children, { onSubmitCallback: submitCallback });

    return (
      <>
        <Button
          label={actionBtn.label}
          onClick={() => {
            setSideBarOpened(!sideBarOpened);
          }}
          size="s"
          iconRight={actionBtn.icon}
          category="outlined"
          ref={ref}
          isDisabled={actionBtn.isDisabled || false}
          data-cy={actionBtn.dataCy}
        />
        {sideBarOpened && (
          <SideBarModal
            title={title}
            isOpened={sideBarOpened}
            closeOnOutsideClick={true}
            onClose={handleClickCloseSidebar}>
            {childrenWithCallback}
          </SideBarModal>
        )}
      </>
    );
  }
);
// needed because the component is wrapped with a forwardRef
AdminSidebarModal.displayName = 'AdminSidebarModal';

export default AdminSidebarModal;
