import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Establishment, EstablishmentsReducer } from '../../models/establishment';

import { TEMP_NEW_ESTABLISHMENT_ID } from '../../enum/establishment';

const initialClinics: EstablishmentsReducer = {
  isClinicsLoading: false,
  isDesignCentersLoading: false,
  isLaboratoriesLoading: false,
  clinicList: [],
  laboratoryList: [],
  designCenterList: []
};

const establishmentsSlice = createSlice({
  name: 'establishments',
  initialState: initialClinics,
  reducers: {
    setClinicList: (state, action: PayloadAction<Establishment[]>) => {
      state.clinicList = action.payload;
    },
    addClinicToList: (state, action: PayloadAction<Establishment>) => {
      state.clinicList = [...state.clinicList, action.payload];
    },
    removeNewClinicInList: (state) => {
      state.clinicList = state.clinicList.filter(
        (clinic) => clinic.id !== TEMP_NEW_ESTABLISHMENT_ID
      );
    },
    setLaboratoryList: (state, action: PayloadAction<Establishment[]>) => {
      state.laboratoryList = action.payload;
    },
    setDesignCenterList: (state, action: PayloadAction<Establishment[]>) => {
      state.designCenterList = action.payload;
    },
    addDesignCenterToList: (state, action: PayloadAction<Establishment>) => {
      state.designCenterList = [...state.designCenterList, action.payload];
    },
    removeNewDesignCenterInList: (state) => {
      state.designCenterList = state.designCenterList.filter(
        (designCenter) => designCenter.id !== TEMP_NEW_ESTABLISHMENT_ID
      );
    }
  }
});

const establishmentActions = establishmentsSlice.actions;

export { establishmentsSlice, establishmentActions };
