import {
  FilterProps,
  MenuEntry,
  SortDirection,
  SortReducer,
  TypeColWithNamePropertyPlatform
} from '../../../../models/datagrid.tsx';
import i18next from 'i18next';
import { DropdownMenu, IconButton } from '@platform-storybook/circlestorybook';
import { Establishment } from '../../../../models/user.tsx';

import stylesDataGrid from '../../../../features/datagrid/datagrid-feature.module.scss';
import { EstablishmentPayload } from '../../../../enum/establishment.ts';
import { getColumnOptions } from '../../../../features/datagrid/datagrid.utils.ts';
import { colDeletedAt } from '../../../../features/datagrid/columns.tsx';

const buildMenu = (
  establishment: Establishment,
  editCallBack: (establishment: Establishment) => void,
  deactivateCallback: (establishment: Establishment) => void
): MenuEntry[][] => {
  const menu: MenuEntry[][] = [
    [
      {
        disabled: establishment.deletedAt !== undefined,
        label: i18next.t('action.edit', { ns: 'common' }),
        onClick: () => {
          editCallBack(establishment);
        },
        type: 'button'
      },
      {
        disabled: establishment.deletedAt !== undefined,
        label: i18next.t('action.deactivate', { ns: 'common' }),
        onClick: () => {
          deactivateCallback(establishment);
        },
        type: 'button'
      }
    ]
  ];
  return menu;
};

export const getColumnSettings = (
  editCallBack: (establishment: Establishment) => void,
  deactivateCallback: (establishment: Establishment) => void
): TypeColWithNamePropertyPlatform[] => [
  colDeletedAt,
  {
    ...getColumnOptions('name', i18next.t('establishmentModal.name', { ns: 'user' }), 1)
  },
  {
    ...getColumnOptions(
      'circleCloudRegion',
      i18next.t('establishmentDataGrid.circleCloudRegion', { ns: 'user' }),
      1,
      false
    )
  },
  {
    ...getColumnOptions(
      'address.address',
      i18next.t('establishmentModal.address', { ns: 'user' }),
      1,
      false
    ),
    render: ({ data }) => data?.address?.address || ''
  },
  {
    ...getColumnOptions(
      'address.zipCode',
      i18next.t('establishmentModal.zipCode', { ns: 'user' }),
      1
    ),
    render: ({ data }) => data?.address?.zipCode || '',
    maxWidth: 110
  },
  {
    ...getColumnOptions('address.city', i18next.t('establishmentModal.city', { ns: 'user' }), 1),
    render: ({ data }) => data?.address?.city || ''
  },
  {
    ...getColumnOptions(
      'address.region',
      i18next.t('establishmentDataGrid.region', { ns: 'user' }),
      1,
      false
    ),
    render: ({ data }) => data?.address?.region || ''
  },
  {
    ...getColumnOptions(
      'address.country',
      i18next.t('establishmentModal.country', { ns: 'user' }),
      1
    ),
    render: ({ data }) =>
      data?.address?.country
        ? i18next.t(`countries.${data?.address?.country.toLowerCase()}`, { ns: 'common' })
        : ''
  },
  {
    ...getColumnOptions('menu', '', 1, false),
    maxWidth: 50,
    showColumnMenuTool: false,
    render: ({ data }) => {
      return (
        <DropdownMenu
          data-cy="kebab-menu"
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton faIconClass="ellipsis-vertical" isActive={active} radius="full" />
          )}
          data={buildMenu(data, editCallBack, deactivateCallback)}
        />
      );
    }
  }
];

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value)
    .forEach((filter) => {
      switch (filter.name) {
        case EstablishmentPayload.CLINIC:
        case EstablishmentPayload.LABORATORY:
        case EstablishmentPayload.DESIGN_CENTER:
          filters = `${filters}&filter.${filter.name}Name=$ilike:${filter.value}`;
          break;
        case 'deletedAt':
          filters = `${filters}&filter.deletedAt=${
            filter.value === 'active' ? '$null' : '$not:$null'
          }`;
          break;
        default:
          filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
          break;
      }
    });
  return filters;
};

export const getSort = (sortInfo: SortReducer | SortReducer[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      if (
        sort.name === EstablishmentPayload.CLINIC ||
        sort.name === EstablishmentPayload.LABORATORY ||
        sort.name === EstablishmentPayload.DESIGN_CENTER
      ) {
        urlSort += `${urlSort}&sortBy=${sort.name}Name:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      } else {
        urlSort += `${urlSort}&sortBy=${sort.name}:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      }
    });
  }
  return urlSort;
};

export const rowClassName = ({ data }: { data: Establishment }): string | undefined => {
  if (data.deletedAt) {
    return stylesDataGrid['datagrid-feature__row--disabled'];
  }
  return undefined;
};
