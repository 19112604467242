import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EstablishmentPayload } from '../../enum/establishment';
import { DatagridsSettingsReducer, FilterReducer, SortDirection } from '../../models/datagrid';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';

const initialSettings: DatagridsSettingsReducer = {
  users: {
    filters: [
      { name: 'email', operator: 'startsWith', type: 'string', value: '' },
      { name: 'firstName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'lastName', operator: 'startsWith', type: 'string', value: '' },
      { name: EstablishmentPayload.CLINIC, operator: 'startsWith', type: 'string', value: '' },
      { name: EstablishmentPayload.LABORATORY, operator: 'startsWith', type: 'string', value: '' },
      {
        name: EstablishmentPayload.DESIGN_CENTER,
        operator: 'startsWith',
        type: 'string',
        value: ''
      },
      { name: 'role', operator: 'inlist', type: 'select', value: null },
      { name: 'deletedAt', operator: 'inlist', type: 'select', value: 'active' }
    ],
    sort: {
      name: 'email',
      dir: SortDirection.ASC_AS_NB
    }
  },
  laboratories: {
    filters: [
      { name: 'name', operator: 'startsWith', type: 'string', value: '' },
      { name: EstablishmentPayload.CLINIC, operator: 'startsWith', type: 'string', value: '' },
      { name: EstablishmentPayload.LABORATORY, operator: 'startsWith', type: 'string', value: '' },
      { name: 'address.zipCode', operator: 'startsWith', type: 'string', value: '' },
      { name: 'address.city', operator: 'startsWith', type: 'string', value: '' },
      { name: 'deletedAt', operator: 'inlist', type: 'select', value: 'active' }
    ]
  },
  partners: {
    filters: [
      { name: 'code', operator: 'startsWith', type: 'string', value: '' },
      { name: 'callbackUrl', operator: 'contains', type: 'string', value: '' }
    ]
  },
  products: {
    filters: [
      { name: 'name', operator: 'startsWith', type: 'string', value: '' },
      { name: 'family', operator: 'equals', type: 'string', value: '' },
      { name: 'category', operator: 'equals', type: 'string', value: '' },
      { name: 'circleCadVersion', operator: 'startsWith', type: 'string', value: '' },
      { name: 'deletedAt', operator: 'inlist', type: 'select', value: 'active' }
    ],
    sort: {
      name: 'name',
      dir: SortDirection.ASC_AS_NB
    }
  },
  components: {
    filters: [
      { name: 'name', operator: 'contains', type: 'string', value: '' },
      { name: 'componentType', operator: 'inlist', type: 'select', value: null },
      { name: 'materials', operator: 'inList', type: 'select', value: null },
      { name: 'shapes', operator: 'inList', type: 'select', value: null },
      { name: 'structures', operator: 'inList', type: 'select', value: null },
      { name: 'shades', operator: 'inList', type: 'select', value: null },
      { name: 'brands', operator: 'contains', type: 'string', value: '' },
      { name: 'angulations', operator: 'inList', type: 'select', value: null },
      { name: 'aspects', operator: 'inList', type: 'select', value: null },
      { name: 'implantAttachments', operator: 'inList', type: 'select', value: null },
      { name: 'toothStratificationTechniques', operator: 'inList', type: 'select', value: null },
      { name: 'deletedAt', operator: 'inlist', type: 'select', value: 'active' }
    ],
    sort: {
      name: 'name',
      dir: SortDirection.ASC_AS_NB
    }
  },
  orders: {
    filters: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'patientReference', operator: 'contains', type: 'string', value: '' },
      { name: 'dentistName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'submissionDate', operator: 'eq', type: 'date', value: '' },
      { name: 'currentStep', operator: 'inlist', type: 'select', value: null },
      { name: 'labName', operator: 'contains', type: 'string', value: '' },
      { name: 'validationDate', operator: 'eq', type: 'date', value: '' },
      { name: 'families', operator: 'inlist', type: 'select', value: null },
      { name: 'tags', operator: 'inlist', type: 'select', value: null },
      { name: 'limitShippingDate', operator: 'eq', type: 'date', value: '' },
      { name: 'expectedDate', operator: 'eq', type: 'date', value: '' },
      { name: 'deletedAt', operator: 'inlist', type: 'select', value: 'active' }
    ],
    sort: {
      dir: SortDirection.DESC_AS_NB,
      name: 'submissionDate'
    }
  },
  compatibilities: {
    filters: [
      { name: 'label', operator: 'startsWith', type: 'string', value: '' },
      { name: 'family', operator: 'equals', type: 'string', value: '' },
      { name: 'orderCompatibility', operator: 'equals', type: 'string', value: '' },
      { name: 'toothCompatibility', operator: 'equals', type: 'string', value: '' },
      { name: 'archCompatibility', operator: 'equals', type: 'string', value: '' }
    ],
    sort: {
      name: 'label',
      dir: SortDirection.ASC_AS_NB
    }
  }
};

interface DatagridSettingSetter {
  datagrid: string;
  filters?: FilterReducer[];
  sort?: TypeSingleSortInfo | TypeSingleSortInfo[];
}

const datagridsSettingsSlice = createSlice({
  name: 'dgSettings',
  initialState: initialSettings,
  reducers: {
    setDatagridFilters: (state, action: PayloadAction<DatagridSettingSetter>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].filters = action.payload
        .filters as FilterReducer[];
    },
    setDatagridSort: (
      state: DatagridsSettingsReducer,
      action: PayloadAction<DatagridSettingSetter>
    ): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].sort = action.payload.sort;
    },
    resetDatagridFilters: (state, action: PayloadAction<DatagridSettingSetter>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].filters =
        initialSettings[action.payload.datagrid as keyof DatagridsSettingsReducer].filters;
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].sort =
        initialSettings[action.payload.datagrid as keyof DatagridsSettingsReducer].sort;
    },
    setUsersDatagridFilters: (state, action: PayloadAction<TypeFilterValue>): void => {
      state.users.filters = action.payload as FilterReducer[];
    },
    setUsersDatagridSort: (state, action: PayloadAction<TypeSingleSortInfo>): void => {
      state.users.sort = action.payload;
    },
    resetUsersDatagridFilters: (state): void => {
      state.users.filters = initialSettings.users.filters;
    },
    resetLaboratoriesDatagridFilters: (state): void => {
      state.laboratories.filters = initialSettings.laboratories.filters;
    },
    resetPartnersDatagridFilters: (state): void => {
      state.partners.filters = initialSettings.partners.filters;
    },
    setProductsDatagridFilters: (state, action: PayloadAction<TypeFilterValue>): void => {
      state.products.filters = action.payload as FilterReducer[];
    },
    resetProductsDatagridFilters: (state): void => {
      state.products.filters = initialSettings.products.filters;
    },
    setProductsDatagridSort: (state, action: PayloadAction<TypeSingleSortInfo>): void => {
      state.products.sort = action.payload;
    },
    setComponentsDatagridFilters: (state, action: PayloadAction<TypeFilterValue>): void => {
      state.components.filters = action.payload as FilterReducer[];
    },
    resetComponentsDatagridFilters: (state): void => {
      state.components.filters = initialSettings.components.filters;
    },
    setComponentsDatagridSort: (state, action: PayloadAction<TypeSingleSortInfo>): void => {
      state.components.sort = action.payload;
    },
    setOrdersDatagridFilters: (state, action: PayloadAction<TypeFilterValue>): void => {
      state.orders.filters = action.payload as FilterReducer[];
    },
    setOrdersDatagridSort: (state, action: PayloadAction<TypeSingleSortInfo>): void => {
      state.orders.sort = action.payload;
    },
    resetOrdersDatagridFilters: (state): void => {
      state.orders.filters = initialSettings.orders.filters;
    },
    setCompatibilitiesDatagridFilters: (state, action: PayloadAction<TypeFilterValue>): void => {
      state.compatibilities.filters = action.payload as FilterReducer[];
    },
    setCompatibilitiesDatagridSort: (state, action: PayloadAction<TypeSingleSortInfo>): void => {
      state.compatibilities.sort = action.payload;
    },
    resetCompatibilitiesDatagridFilters: (state): void => {
      state.compatibilities.filters = initialSettings.compatibilities.filters;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<DatagridsSettingsReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialSettings };
    });
  }
});

const datagridSettingsActions = datagridsSettingsSlice.actions;

export { datagridsSettingsSlice, datagridSettingsActions };
