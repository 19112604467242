import styles from './user-details.module.scss';
import {
  Button,
  Chips,
  Dialog,
  Divider,
  IconButton,
  SideBarModal,
  Skeleton,
  Text
} from '@platform-storybook/circlestorybook';
import { Establishment, UserInfo } from '../../models/user.tsx';
import React, { useState } from 'react';
import { isDentistRole, isLaboratory } from '../../utils/utils.tsx';
import { Country, DentistRole } from '../../enum/user.ts';
import { useTranslation } from 'react-i18next';
import { EstablishmentPayload } from '../../enum/establishment.ts';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import { useUpdateUser } from '../../hooks/useUpdateUser.tsx';
import EstablishmentForm from '../../pages/private/main-users-page/users-page/detail-user-page/user-forms/EstablishmentForm.tsx';

/**
 * Interface to define a generic object with React state.
 * When we call EstablishmentSection, we know about the establishment type we want to display.
 * An establishment need a sidebar to edit it, and we need to pass to this generic EstablishmentSection,
 * the establishment sidebar states (open or close). So we pass the concern React state to avoid more useless
 * calculation.
 */
interface StateEditEstablishment {
  setState: (state: boolean) => void;
  getState: boolean;
}

interface Props {
  user: UserInfo;
  establishment: Establishment;
  establishmentApiKeyBack: string;
  stateSideBar: StateEditEstablishment;
  isReadOnly: boolean;
  isLoading: boolean;
  allowEdit?: boolean;
}

const EstablishmentDisplay: React.FC<Props> = ({
  user,
  establishment,
  establishmentApiKeyBack,
  stateSideBar,
  isReadOnly,
  isLoading,
  allowEdit = true
}) => {
  const { t } = useTranslation(['user']);
  const { handleUpdateUser, isUpdateUserLoading } = useUpdateUser();

  const [isDialogAuthorizeUserOpened, setIsDialogAuthorizeUserOpened] = useState<boolean>(false);

  const establishmentTranslationKey =
    user?.role &&
    establishment?.type &&
    isDentistRole(user.role) &&
    isLaboratory(establishment.type)
      ? `${DentistRole.DENTIST}_${establishment.type}`
      : (establishment.type as string);

  const allowUserToOrder = async () => {
    if (user && user.email) {
      handleUpdateUser(
        { isAllowedToOrder: true },
        user?.email,
        t('datagrid.setAuthorizationOrder.success')
      );
    }
    setIsDialogAuthorizeUserOpened(false);
  };

  return (
    <>
      <section className={styles['user-detail__content__section']}>
        <header className={styles['user-detail__content__section__header--name']}>
          <div className={styles['user-detail__content__section__header__name']}>
            <Text
              className={styles['user-detail__content__section__header__title']}
              size="s"
              type="title"
              label={t(`userForm.${establishmentTranslationKey}`)}></Text>
            {isReadOnly && user && allowEdit && (
              <IconButton
                data-cy={`icon-button-${establishment.type?.toLocaleLowerCase()}`}
                iconSize="sm"
                faIconClass="fa-pen-to-square"
                onClick={() => stateSideBar.setState(true)}
                radius="full"
              />
            )}
          </div>
          {isReadOnly &&
            user?.role &&
            establishment.type &&
            isDentistRole(user?.role) &&
            isLaboratory(establishment.type) && (
              <div className={styles['user-detail__content__section__actions']}>
                <Button
                  className={styles['user-detail__content__section__actions__button']}
                  label={t('datagrid.setAuthorizationOrder.action')}
                  onClick={() => setIsDialogAuthorizeUserOpened(true)}
                  isDisabled={user.isAllowedToOrder || user.deletedAt !== undefined}
                  size="s"
                />

                <Dialog
                  title={t('datagrid.setAuthorizationOrder.dialog.title', {
                    user: `${user?.firstName} ${user?.lastName}`
                  })}
                  text={t('datagrid.setAuthorizationOrder.dialog.text')}
                  isOpened={isDialogAuthorizeUserOpened}
                  cancelButtonLabel={t('action.cancel', { ns: 'common' })}
                  confirmButtonLabel={t('datagrid.setAuthorizationOrder.dialog.action')}
                  isLoading={isUpdateUserLoading}
                  onCancel={() => setIsDialogAuthorizeUserOpened(false)}
                  onConfirm={allowUserToOrder}
                />
              </div>
            )}
        </header>
        {isLoading ? (
          <>
            <Skeleton type="text" className="skeleton" />
            <Skeleton type="text" className="skeleton" />
            <Skeleton type="text" className="skeleton" />
            <Skeleton type="text" />
          </>
        ) : (
          <>
            <Text label={establishment.name}></Text>
            {establishment.address && (
              <>
                <Text label={establishment.address.address}></Text>
                {establishment.address.additionalAddress && (
                  <Text label={establishment.address.additionalAddress}></Text>
                )}
                <div className={styles['user-detail__content__section__main__item']}>
                  <Text
                    label={
                      establishment.address.zipCode +
                      ' ' +
                      establishment.address.city +
                      ' ' +
                      (establishment.address.region ? establishment.address.region : '')
                    }></Text>
                  {Object.values(Country).includes(establishment.address.country as Country) && (
                    <Text
                      label={t(`countries.${establishment?.address?.country?.toLowerCase()}`, {
                        ns: 'common'
                      })}></Text>
                  )}
                </div>
              </>
            )}
            {establishmentApiKeyBack === EstablishmentPayload.LABORATORY && (
              <div>
                <Text color={ColorPropsEnum.GREY} label={t('userForm.userAllowedToOrder')}></Text>
                <Chips
                  firstLabel={t(user?.isAllowedToOrder ? 'yes' : 'no', {
                    ns: 'common'
                  })}
                  color={user?.isAllowedToOrder ? ColorPropsEnum.SUCCESS : ColorPropsEnum.WARNING}
                />
              </div>
            )}
          </>
        )}
        <SideBarModal
          title={t(`userForm.${establishmentTranslationKey}`)}
          isOpened={stateSideBar.getState}
          onClose={() => stateSideBar.setState(false)}>
          <EstablishmentForm
            email={user?.email as string}
            establishment={establishment}
            establishmentApiKeyBack={establishmentApiKeyBack}
            saveCallback={handleUpdateUser}
            closeSideNavCallback={stateSideBar.setState}
          />
        </SideBarModal>
      </section>
      <Divider />
    </>
  );
};

export default EstablishmentDisplay;
