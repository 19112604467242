import { useTranslation } from 'react-i18next';
import { Tabs } from '@platform-storybook/circlestorybook';
import { TabsType } from '../../../models/navigation';
import styles from './dashboard-page.module.scss';

const DashboardPage = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['common']);
  const tabs: TabsType[] = [
    {
      label: t('tabs.cloudManager', { ns: 'dashboard' }),
      to: '/dashboard/cloud-manager',
      disabled: false
    },
    {
      label: t('tabs.googleMonitoring', { ns: 'dashboard' }),
      disabled: true,
      to: '/dashboard/google-monitoring'
    },
    {
      label: t('tabs.googleLooker', { ns: 'dashboard' }),
      disabled: true,
      to: '/dashboard/google-looker'
    }
  ];

  return (
    <section>
      <Tabs tabs={tabs} id={'dashboard'} className={styles['dashboard__tab']}></Tabs>
      <div>{children}</div>
    </section>
  );
};

export default DashboardPage;
