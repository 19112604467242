import styles from './create-edit-partner.module.scss';
import { Button, TextField, Box } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useForm from '../../../../utils/useForm.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { ColorPropsEnum } from '../../../../enum/color.enum.ts';
import { toKebabCase } from '../../../../utils/utils.tsx';
import {
  useCreatePartnerMutation,
  usePatchPartnerMutation
} from '../../../../services/partners.services.tsx';
import { Partner } from '../../../../models/partner.tsx';

const identifierColumn = 'code';

type props = {
  partner?: Partner;
  setIsReloading?: (loading: boolean) => void;
  onClose: () => void;
};

const CreateEditPartnerForm = ({ partner, onClose, setIsReloading }: props) => {
  const { t } = useTranslation(['user']);
  const dispatch = useDispatch();

  const [createPartner, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate }] =
    useCreatePartnerMutation();
  const [patchPartner, { isLoading: isLoadingPatch, isSuccess: isSuccessPatch }] =
    usePatchPartnerMutation();

  const renderTextField = (field: keyof typeof values) => (
    <TextField
      key={field}
      label={t(`partnerModal.${field}`)}
      id={field}
      name={field}
      value={values[field]}
      type="text"
      helperText={errors[field] ? t(`partnerModal.${field}Mandatory`) : ''}
      variant={errors[field] ? 'danger' : 'default'}
      className={styles['create-edit-partner__form__textfield']}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={t(`partnerModal.placeholder.${field}`)}
      isDisabled={partner && field === identifierColumn}
      data-cy={`create-edit-partner-${toKebabCase(field as string)}`}
    />
  );

  const submit = () => {
    const newPartner: Partner = {
      code: values.code as string,
      callbackUrl: values.callbackUrl as string
    };

    if (partner) {
      const partnerToUpdate = {
        ...newPartner,
        code: partner.code
      };
      patchPartner(partnerToUpdate);
    } else {
      createPartner(newPartner);
    }
  };

  // change isReload in parent when isLoadingCreate or isLoadingUpdate changes.
  useEffect(() => {
    if (setIsReloading) {
      setIsReloading(isLoadingCreate || isLoadingPatch);
    }
  }, [isLoadingCreate, isLoadingPatch]);

  useEffect(() => {
    if (isSuccessCreate || isSuccessPatch) {
      dispatch(
        feedbackActions.setToast({
          message: partner
            ? t('partnerDataGrid.toast.updated')
            : t('partnerDataGrid.toast.created'),
          type: ToastType.SUCCESS
        })
      );
      onClose();
    }
  }, [isSuccessCreate, isSuccessPatch]);

  const initialValue = {
    code: partner?.code ?? '',
    callbackUrl: partner?.callbackUrl ?? ''
  };

  const { values, errors, handleChange, handleBlur, handleSubmit } = useForm(initialValue, submit);

  return (
    <Box color={ColorPropsEnum.WHITE} className={styles['create-edit-partner']}>
      <form className={styles['create-edit-partner__form']} onSubmit={handleSubmit}>
        {[identifierColumn, 'callbackUrl'].map(renderTextField)}
        <div className="form__submit-button form__submit-button--right">
          <Button
            label={t(`${partner ? 'action.update' : 'action.create'}`, { ns: 'common' })}
            isLoading={isLoadingPatch || isLoadingCreate}
            type="submit"
            data-cy={'create-edit-partner-submit'}
          />
        </div>
      </form>
    </Box>
  );
};

export default CreateEditPartnerForm;
