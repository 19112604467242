import { api } from './api';
import { Product, ProductLabActivation, ProductUpdateForm } from '../models/product';
import { ServicePaginatedResponse } from '../models/datagrid';
import { getLocalizedProperty } from '../utils/utils';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActiveProducts: builder.query<ServicePaginatedResponse, { filters?: string; sort?: string }>(
      {
        query: ({ filters, sort }) => {
          if (!sort) sort = `&sortBy=${getLocalizedProperty('label')}:ASC`;
          return `/products?page=1&limit=-1&filter.deletedAt=$null${filters || ''}${sort}`;
        },
        providesTags: ['Products']
      }
    ),
    getOneProduct: builder.query<Product, string | undefined>({
      query: (id) => {
        return `/products/${id}`;
      },
      providesTags: ['OneProduct']
    }),
    patchProduct: builder.mutation<Product, ProductUpdateForm>({
      query: ({ id, ...patch }) => ({
        url: `/products/${id}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['Products', 'OneProduct']
    }),
    getLabProducts: builder.query<ProductLabActivation[], number>({
      query: (productId) => ({
        url: `/products/${productId}/labs`
      })
    }),
    deactivateLabProduct: builder.mutation<void, { productId: number; labId: number }>({
      query: (params) => ({
        url: `/products/${params.productId}/labs/${params.labId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OneProduct']
    }),
    activateLabProduct: builder.mutation<
      ProductLabActivation[],
      { productId: number; lab: ProductLabActivation }
    >({
      query: (params) => ({
        url: `/products/${params.productId}/labs`,
        method: 'POST',
        body: params.lab
      }),
      invalidatesTags: ['Products', 'OneProduct']
    })
  })
});

export const {
  useGetOneProductQuery,
  useGetActiveProductsQuery,
  usePatchProductMutation,
  useLazyGetLabProductsQuery,
  useActivateLabProductMutation,
  useDeactivateLabProductMutation
} = productsApi;
