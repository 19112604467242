import i18next from 'i18next';
import {
  AdminRole,
  DentistRole,
  DesignerRole,
  LabUserRole
} from '../../../../../../../enum/user.ts';
import { UserManagementPermissions } from '../../../../../../../models/connected-user.tsx';

/**
 * Returns grouped array of user roles available for creation
 * @param connectedUserPermissions
 */
export const getAvailableRolesForCurrentUser = (
  connectedUserPermissions: UserManagementPermissions
) => {
  const allAllowedRoles = [];
  const assistantRole = {
    label: i18next.t(`roles.${DentistRole.ASSISTANT}`, { ns: 'user' }),
    value: DentistRole.ASSISTANT
  };
  const dentistRole = {
    label: i18next.t(`roles.${DentistRole.DENTIST}`, { ns: 'user' }),
    value: DentistRole.DENTIST
  };
  const dentistRoles = [assistantRole, dentistRole];
  const labUserRoles = [
    {
      label: i18next.t(`roles.${LabUserRole.PROSTHETIST}`, { ns: 'user' }),
      value: LabUserRole.PROSTHETIST
    },
    {
      label: i18next.t(`roles.${LabUserRole.SUPERVISOR}`, { ns: 'user' }),
      value: LabUserRole.SUPERVISOR
    },
    {
      label: i18next.t(`roles.${LabUserRole.SUPPORT}`, { ns: 'user' }),
      value: LabUserRole.SUPPORT
    },
    {
      label: i18next.t(`roles.${LabUserRole.TECHNICIAN}`, { ns: 'user' }),
      value: LabUserRole.TECHNICIAN
    },
    {
      label: i18next.t(`roles.${LabUserRole.LAB_DESIGNER}`, { ns: 'user' }),
      value: LabUserRole.LAB_DESIGNER
    }
  ];
  const designerRoles = [
    {
      label: i18next.t(`roles.${DesignerRole.DESIGNER}`, { ns: 'user' }),
      value: DesignerRole.DESIGNER
    }
  ];
  const adminRoles = [
    {
      label: i18next.t(`roles.${AdminRole.ADMIN}`, { ns: 'user' }),
      value: AdminRole.ADMIN
    },
    {
      label: i18next.t(`roles.${AdminRole.ADMIN_PARTNER}`, { ns: 'user' }),
      value: AdminRole.ADMIN_PARTNER
    }
  ];

  if (connectedUserPermissions.canCreateDentist) {
    if (connectedUserPermissions.canCreateAssistant) {
      allAllowedRoles.push(dentistRoles);
    } else {
      allAllowedRoles.push([dentistRole]);
    }
  }

  if (connectedUserPermissions.canCreateLabUserRole) {
    allAllowedRoles.push(labUserRoles, designerRoles);
  }

  if (connectedUserPermissions.canCreateAdminRole) {
    allAllowedRoles.push(adminRoles);
  }

  return allAllowedRoles;
};
