import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  Box,
  Button,
  Fieldset,
  Link,
  SideBarModal,
  Skeleton,
  Text,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import { productSelector } from '../../../../../../store/products/products.selectors';
import styles from '../create-product-page.module.scss';
import { CreateProductNavProps } from '../CreateProductPage';
import AddComponentForm from './add-component-form/AddComponentForm';
import { productsActions } from '../../../../../../store/products/products.reducers';
import {
  ComponentInProductCreationForm,
  ProductCreationForm
} from '../../../../../../models/product';
import ListFeature from '../../../../../../features/list/ListFeature';
import { getComponentListItemSupportingText } from '../../product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { ItemTypeBgColorEnum } from '../../../../../../features/list/list-item/list-item.utils.ts';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services.ts';

const ComponentsForm = ({ previousStepCallback, nextStepCallback }: CreateProductNavProps) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const product = useAppSelector(productSelector);
  const [sidebarIsOpened, setSidebarIsOpened] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [componentToEdit, setComponentToEdit] = useState<
    ComponentInProductCreationForm | undefined
  >(undefined);
  const { isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();

  const handleEscKey = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setSidebarIsOpened(false);
    }
  }, []);

  // Form configuration
  const validateForm = (): void => {
    if (!product?.components?.length) {
      setDisplayError(true);
    } else {
      if (nextStepCallback) nextStepCallback();
    }
  };
  const onComponentAdded = () => {
    setSidebarIsOpened(false);
  };
  const removeComponentFromProduct = (component: ComponentInProductCreationForm) => {
    const updatedComponents: ComponentInProductCreationForm[] | undefined =
      product?.components?.filter((thisComponent) => thisComponent.id !== component.id);

    // Update product with new set of components
    // If we've juste removed the last component, set components key in product to undefined
    dispatch(
      productsActions.setProduct({
        ...product,
        components: updatedComponents?.length ? updatedComponents : undefined
      } as ProductCreationForm)
    );
  };
  const editComponent = (component: ComponentInProductCreationForm) => {
    setComponentToEdit(component);
    setSidebarIsOpened(true);
  };
  const buildComponentsList = () => {
    const itemsData = product?.components?.map((component) => {
      return {
        itemKey: component.id.toString(),
        headline: component.label,
        supportingText: getComponentListItemSupportingText(component),
        actions: [
          {
            faIconClass: 'pen-to-square',
            onClick: () => editComponent(component)
          },
          {
            faIconClass: 'trash',
            onClick: () => removeComponentFromProduct(component),
            color: ColorPropsEnum.DANGER
          }
        ],
        bgColor: ItemTypeBgColorEnum.WHITE
      };
    });

    return itemsData && <ListFeature items={itemsData} />;
  };

  // Hooks
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  // Return form
  return areCommonTypesLoading ? (
    <Skeleton />
  ) : (
    <>
      <form className={styles['create-product-page__form']}>
        <Fieldset
          size="m"
          className={[
            styles['create-product-page__form__fieldset'],
            styles['create-product-page__form__fieldset--with-footer']
          ].join(' ')}>
          <div className={styles['create-product-page__form__fieldset--with-footer__content']}>
            <div
              className={styles['create-product-page__form__fieldset--with-footer__content__btns']}>
              <Button
                className={
                  styles['create-product-page__form__fieldset--with-footer__content__btns__primary']
                }
                label={t('products.productForm.components.addOne')}
                type="button"
                iconRight="fa-plus"
                onClick={() => setSidebarIsOpened(true)}
                category="outlined"
              />
              <Tooltip>
                <span
                  className={
                    styles[
                      'create-product-page__form__fieldset--with-footer__content__btns__helper'
                    ]
                  }>
                  <FontAwesomeIcon icon={faQuestion} size="xs" />
                </span>
                <TooltipContent>
                  <Text
                    color={ColorPropsEnum.WHITE}
                    label={t('products.productForm.components.example')}></Text>
                </TooltipContent>
              </Tooltip>
            </div>
            {product?.components && (
              <Box className={styles['create-product-page__box--content']}>
                {buildComponentsList()}
              </Box>
            )}
            {!product?.components?.length && (
              <Box className={styles['create-product-page__box--no-content--with-button']}>
                <Text
                  label={t('products.productForm.components.helper')}
                  color={displayError ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}></Text>
              </Box>
            )}
          </div>
          <div className={styles['create-product-page__form__fieldset--with-footer__footer']}>
            <Text label={t('products.productForm.components.footer.label')}></Text>
            <Link
              label={t('products.productForm.components.footer.link')}
              target="_blank"
              isUnderlined={true}
              href="/catalog/components/create"></Link>
          </div>
        </Fieldset>
        <div className="form__buttons">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="outlined"
            onClick={previousStepCallback}
            iconLeft="fa-chevron-left"
          />
          <Button
            label={t('action.next', { ns: 'common' })}
            type="button"
            iconRight="fa-chevron-right"
            onClick={validateForm}
          />
        </div>
      </form>
      <SideBarModal
        title={t('products.productForm.components.addOne')}
        isOpened={sidebarIsOpened}
        closeOnOutsideClick={true}
        onClose={() => {
          setSidebarIsOpened(false);
          setComponentToEdit(undefined);
        }}>
        <AddComponentForm
          onSubmitCallback={onComponentAdded}
          component={componentToEdit}
          resetComponentTypeToEdit={() => setComponentToEdit(undefined)}
        />
      </SideBarModal>
    </>
  );
};

export default ComponentsForm;
